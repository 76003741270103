<template>
  <component
    v-if="!source.hidden || isVirt"
    :is="blockComponent[source.type] || unknownBlock"
    :children="source"
    :index="index"
    :fatherType="source.type"
    :prevChild="prevChild || sources[index - 1]"
    :filter="filter"
    :hideRowCells="hideRowCells"
    :crspan="crspan"
    :fatherBlockId="fatherBlockId"
    :level="level + 1"
    :olIndex="olIndex"
    :childLen="childLen"
    :showMask="showMask"
  >
    <template
      v-slot:default="slotProps"
      v-if="source.childrens && source.childrens.length > 0"
    >
      <template v-for="(child, index) in source.childrens">
        <page-main
          :filter="slotProps.filter"
          :hideRowCells="slotProps.hideRowCells"
          :crspan="slotProps.crspan"
          :childLen="slotProps.childLen"
          :source="child"
          :key="child.blockId"
          :index="index"
          :sources="source.childrens"
          :fatherType="source.type"
          :fatherBlockId="source.blockId"
          :level="slotProps.level"
          :olIndex="slotProps.olIndex"
          :prevChild="index - 1 >= 0 ? source.childrens[index - 1] : null"
        ></page-main>
      </template>
    </template>
  </component>
  <!-- 递归 -->
</template>
<script>
import { blockComponent } from "../utils/utils";
import unknownBlock from "./unknown-block.vue"; //未知 Block

export default {
  name: "page-main",
  components: {
    unknownBlock,
  },
  props: {
    source: {
      // here is: {uid: 'unique_1', text: 'abc'}
      type: Object,
      default() {
        return {};
      },
    },
    fatherType: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    prevChild: {
      type: Object,
      default: null,
    },
    sources: {
      type: Array,
      default() {
        return [];
      },
    },
    filter: {
      type: Array,
      default() {
        return [];
      },
    },
    hideRowCells: {
      type: Array,
      default() {
        return [];
      },
    },
    crspan: {
      type: Object,
      default() {
        return {};
      },
    },
    fatherBlockId: {
      type: String,
      default: "",
    },
    level: {
      type: Number,
      default: 0,
    },
    olIndex: {
      type: Number,
      default: 0,
    },
    childLen: {
      type: Number,
      default: 0,
    },
    showMask:{
      type: Boolean,
      default: true,
    }
  },
  inject: ['isVirt'],
  data() {
    return {
      blockComponent: blockComponent,
      unknownBlock,
    };
  },
  created() {
    // console.log(this.isVirt);
  }
};
</script>
<style>
.page-block-children {
  font-size: 16px;
  color: var(--text-title);
}
</style>