<template>
  <fragment>
    <div
      v-if="children"
      class="block docx-grid_column-block"
      :style="deviceStyle"
    >
      <div class="grid-column-block">
        <div class="render-unit-wrapper">
          <slot></slot>
        </div>
      </div>
    </div>
    <div
      v-if="isMobile"
      class="bear-virtual-renderUnit-placeholder"
      style="height: 0px; margin-top: 0px; margin-bottom: 0px; display: none"
    >
      <span></span>
    </div>
    <div class="column-gap j-column-gap" v-else>
      <div contenteditable="false" class="column-gap-inner"></div>
    </div>
  </fragment>
</template>
<script>
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "column-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
    childLen: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      isMobile,
    };
  },
  computed: {
    deviceStyle() {
      return this.isMobile
        ? `flex-grow: ${this.children.blockAttr.grid_column.width_ratio};flex-shrink: 0;width: unset;`
        : `flex-grow: 0; flex-shrink: 0; width: calc((100% - ${
            16 * this.childLen
          }px) * ${this.children.blockAttr.grid_column.width_ratio / 100})`;
    },
  },
};
</script>
<style >
</style>