var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c("div", { staticClass: "block docx-image-block" }, [
        _c(
          "div",
          {
            ref: `image-${_vm.children.block}`,
            staticClass: "image-block",
            class: _vm.getAlignFlex(_vm.children.blockAttr.image.align),
          },
          [
            _c(
              "div",
              {
                staticClass: "image-block-width-wrapper flash-block-content",
                style: `width: ${_vm.imgStyle.width}px;`,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-block-container",
                    style: `width: ${_vm.imgStyle.width}px;padding-top: ${
                      (_vm.imgStyle.height / _vm.imgStyle.width) * 100
                    }%;`,
                  },
                  [
                    _c("div", { staticClass: "resizable-wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: _vm.showBorder
                            ? "animate__animated animate__heartBeat"
                            : "",
                        },
                        [
                          _vm._l(_vm.textArea, function (area, areaIndex) {
                            return [
                              _vm.searchKey &&
                              area.text.indexOf(_vm.searchKey) > -1
                                ? _c("div", {
                                    staticClass: "area-box",
                                    class:
                                      _vm.children.blockId ==
                                        _vm.currentBlockId &&
                                      _vm.currentSign ==
                                        _vm.getAreaIndex(areaIndex)
                                        ? "tag-img-active"
                                        : "",
                                    style: {
                                      top:
                                        (area.y *
                                          (Math.min(
                                            _vm.imgStyle.width,
                                            _vm.maxWidth
                                          ) *
                                            (_vm.imgStyle.height /
                                              _vm.imgStyle.width))) /
                                          _vm.children.blockAttr.image.height +
                                        "px",
                                      left:
                                        (area.x *
                                          Math.min(
                                            _vm.imgStyle.width,
                                            _vm.maxWidth
                                          )) /
                                          _vm.children.blockAttr.image.width +
                                        "px",
                                      width:
                                        (area.width *
                                          Math.min(
                                            _vm.imgStyle.width,
                                            _vm.maxWidth
                                          )) /
                                          _vm.children.blockAttr.image.width +
                                        "px",
                                      height:
                                        (area.height *
                                          (Math.min(
                                            _vm.imgStyle.width,
                                            _vm.maxWidth
                                          ) *
                                            (_vm.imgStyle.height /
                                              _vm.imgStyle.width))) /
                                          _vm.children.blockAttr.image.height +
                                        "px",
                                    },
                                    attrs: {
                                      id: `${
                                        _vm.children.blockId
                                      }-${_vm.getAreaIndex(areaIndex)}`,
                                    },
                                  })
                                : _vm._e(),
                            ]
                          }),
                          _vm._v(" "),
                          _c("img", {
                            style: _vm.imgStyle,
                            attrs: { src: _vm.imgUrl },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.showPreview.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }