<template>
    <div class="block docx-view-block">
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'view-block'
}
</script>