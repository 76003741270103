var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ace-line" },
    [
      _vm._l(_vm.elements, function (item, index) {
        return [
          item.text_run
            ? _c(
                "span",
                {
                  key: index,
                  class: [
                    _vm.getEleStyle(item.text_run.text_element_style),
                    _vm.isUrlRegExp(item.text_run.content)
                      ? " link-text-color "
                      : "",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.openUrl(
                        item.text_run.text_element_style.link,
                        item.text_run.content,
                        $event
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(item.text_run.content))]
              )
            : _vm._e(),
          item.mention_doc
            ? _c(
                "span",
                {
                  key: index,
                  class: [
                    _vm.getEleStyle(item.mention_doc.text_element_style),
                    item.mention_doc.obj_type == 22 ||
                    item.mention_doc.obj_type == 16
                      ? " link-text-color "
                      : "", //22 docx 16 wiki
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.openMentionUrl(
                        item.mention_doc.url,
                        item.mention_doc.obj_type
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(item.mention_doc.title))]
              )
            : _vm._e(),
          item.inline_block
            ? _c(
                "span",
                {
                  key: index,
                  class: [
                    _vm.getEleStyle(item.inline_block.text_element_style),
                  ],
                },
                [
                  _vm._v(
                    _vm._s(_vm.getLineBlockText(item.inline_block.block_id))
                  ),
                ]
              )
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          attrs: {
            "data-string": "true",
            "data-enter": "true",
            "data-leaf": "true",
          },
        },
        [_vm._v("​")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }