<template>
  <div v-if="children" class="block docx-heading3-block" :class="[`block-${children.blockId}`,  children.blockStyles && children.blockStyles.textBackground ? 'background':'']">
    <div class="heading-content" :class="pageStyle">
      <v-spantext :elements="elements" :children="children"></v-spantext>
    </div>
    <div >
      <slot></slot>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <toggleContent v-if="mode == 0"  :fold="children.fold" :blockId="children.blockId" :foldChild="children.foldChild"></toggleContent>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";
import toggleContent from "./toggle-content.vue";

export default {
  name: "h3-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  components:{
    toggleContent
  },
  data() {
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
      mode: this.$route.query.mode || '0',
    };
  },
  computed: {
    elements() {
      return this.children.blockAttr.heading3.elements || [];
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.heading3.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
  },
  methods: {},
};
</script>
<style >
.page-block-children .block.docx-heading3-block {
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 20px;
}
.block.docx-heading3-block .el-icon-caret-bottom{
  display: none;
}
.listitem:hover .block.docx-heading3-block .el-icon-caret-bottom{
  display: inline-block;
}
</style>