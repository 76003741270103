<template>
  <div class="block docx-callout-block">
    <div class="callout-block" :style="blockStyle">
      <div
        contenteditable="false"
        class="callout-emoji-container"
        v-if="children.blockAttr.callout.emoji_id"
      >
        <div class="callout-block-emoji disabled">
          <span class="emoji-mart-emoji emoji-mart-emoji-native emoji-mart-new"
            ><span style="font-size: 20px">{{
              emojis[children.blockAttr.callout.emoji_id]
            }}</span></span
          >
        </div>
      </div>
      <div class="callout-block-children">
        <div class="callout-render-unit">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emojis from "../data/emoji.json";
import { BorderColor, CalloutBackgroundColor } from "../utils/style";
export default {
  name: "light-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      emojis: emojis,
    };
  },
  computed: {
    blockStyle() {
      let styles = "";
      let callout = this.children.blockAttr.callout;
      if (callout.background_color) {
        styles += `background-color:var(${
          CalloutBackgroundColor[callout.background_color]
        });`;
      }
      if (callout.border_color) {
        styles += `border-color: var(${BorderColor[callout.border_color]});`;
      }
      return styles;
    },
  },
  methods: {},
};
</script>
<style>
.page-block-header {
  position: relative;
  z-index: 0;
  padding: 20px 0 0 20px;
  margin: 0 0 22px;
  word-break: break-word;
}
.page-block-content {
  position: relative;
  font-size: 34px;
  min-height: 55.25px;
  line-height: 1.625;
  color: var(--text-title);
  font-weight: 500;
}
.callout-block {
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 8px;
}

.callout-emoji-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 16px;
  height: 26px;
}
.callout-block-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
}
.callout-block-emoji .emoji-mart-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  line-height: 24px;
  border-radius: 4px;
  cursor: pointer;
}
.callout-block-emoji.disabled .emoji-mart-emoji {
  cursor: default;
}
.callout-block-children {
  margin-left: 32px;
}
</style>