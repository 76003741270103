<template>
  <div class="fs-highlight" v-if="searchKey && highlight.length > 0">
    <span
      v-for="item in highlight"
      class="doc-tag"
      :id="`${blockId}-${item.index}`"
      :data-index="item.index"
      :class="
        blockId == currentBlockId && currentSign == item.index
          ? 'tag-active'
          : ''
      "
      :style="{
        left: item.left + 'px',
        top: item.top + 'px',
        width: item.width + 'px',
        height: item.height + 'px',
      }"
    ></span>
  </div>
</template>
<script>
export default {
  name: "high-light",
  props: {
    blockId: {
      type: String,
      default: "",
    },
  },
  inject: ["isVirt"],
  computed: {
    searchKey() {
      return this.$store.state.searchKey;
    },
    searchResultCount() {
      return this.$store.state.searchResultCount;
    },
    searchResultIndex() {
      return this.$store.state.searchResultIndex;
    },
    searchPageBlockIds() {
      return this.$store.state.searchPageBlockIds;
    },
    currentBlockId() {
      let index = this.searchResultIndex - 1;
      if (index < 0) {
        return "";
      }
      return (
        this.searchPageBlockIds[index] && this.searchPageBlockIds[index].blockId
      );
    },
    currentSign() {
      let index = this.searchResultIndex - 1;
      if (index < 0) {
        return 0;
      }
      return (
        this.searchPageBlockIds[index] && this.searchPageBlockIds[index].index
      );
    },
  },
  data() {
    return {
      highlight: [],
      wrapperInfo: {},
      lineHeight: 0,
      childNodes: null,
      sign: 0,
    };
  },
  watch: {
    searchKey() {
      this.highlight = [];
      this.handleSearch();
    },
  },
  mounted() {
    this.rfresh = this.debounce(() => {
      this.highlight = [];
      this.$store.commit("setCount", 0);
      this.$store.commit("clearSearchBlockIds");
      this.handleSearch();
    }, 500);
    window.addEventListener("resize", this.rfresh);
    if (!this.searchKey) return;
    this.$nextTick(() => {
      this.handleSearch();
    });
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.rfresh);
  },
  methods: {
    debounce(func, wait, immediate) {
      //定时器变量
      var timeout;
      return function () {
        //每次触发scrolle，先清除定时器
        clearTimeout(timeout);
        //指定多少秒后触发事件操作handler
        timeout = setTimeout(func, wait);
      };
    },
    handleSearch() {
      if (!this.searchKey) return;
      let wrapperRef = document.querySelector(`.block-${this.blockId}`);
      if (!wrapperRef) return;
      this.wrapperInfo = wrapperRef.getBoundingClientRect();
      let editorRef = document.querySelector(
        `.block-${this.blockId} .ace-line`
      );
      if (!editorRef) return;
      this.sign = 0
      this.getLineHeight(editorRef);
      let text = editorRef.innerText;
      this.childNodes = editorRef.childNodes;
      // let regExp = new RegExp(this.searchKey, "g");
      this.searchTextRange(text, this.searchKey);
      // this.rangeText(i, textNodes, regExp, text, this.searchKey, lineHeight);
    },
    searchTextRange(text, key) {
      let regExp = new RegExp($.util.regScapeStr(key), "g");
      let result = null;
      regExp.lastIndex = 0;
      while ((result = regExp.exec(text))) {
        const { index } = result;
        let start = index;
        let end = start + key.length;
        let childNode = null;
        let nodesLength = 0;
        let nodeRanges = [];
        for (let i = 0; i < this.childNodes.length; i++) {
          let child = this.childNodes[i];
          if(child.nodeType === 8) continue;
          child = this.getTextNode(child);
          let totalLength = child.length + nodesLength;
          if (totalLength > start && totalLength >= end) {
            nodeRanges.push({
              node: child,
              start: start - nodesLength,
              end: end - nodesLength,
            });
            break;
          }
          if (totalLength > start && totalLength < end) {
            nodeRanges.push({
              node: child,
              start: start - nodesLength,
              end: totalLength - nodesLength,
            });
            start += totalLength - start;
          }
          nodesLength += child.length;
        }
        for (let j = 0; j < nodeRanges.length; j++) {
          let child = nodeRanges[j];
          this.setRangeRect(child.node, child.start, child.end);
        }
        // if(this.isVirt){
        // this.$store.commit("setCount", this.searchResultCount + 1);
        // this.$store.commit("setSearchBlockIds", {
        //   blockId: this.blockId,
        //   index: this.sign,
        // });
        // }
        this.sign++;
      }
    },
    getTextNode(node) {
      while (node && node.nodeType != 3) {
        if (node.nodeType == 8) {
          node = node.nextSibling;
        } else {
          node = node.firstChild;
        }
      }
      return node;
    },
    getLineHeight(editorRef) {
      const textNodes = editorRef.childNodes;
      const standardRange = document.createRange();
      let tempNode = this.getTextNode(editorRef);
      if (!tempNode) return;
      standardRange.setStart(tempNode, 0);
      standardRange.setEnd(tempNode, 0);
      const standardRangeReact = standardRange.getBoundingClientRect();
      this.lineHeight = standardRangeReact.height;
    },
    setRangeRect(textNode, start, end) {
      const range = document.createRange();
      range.setStart(textNode, start);
      range.setEnd(textNode, end);
      const rangeReact = range.getBoundingClientRect();
      if (Math.round(rangeReact.height) === Math.round(this.lineHeight)) {
        this.highlight.push(this.calRectInfo(rangeReact));
      } else {
        let i = 0;
        let j = 1;
        while (j <= end - start) {
          const subRange = document.createRange();
          subRange.setStart(textNode, start + i);
          subRange.setEnd(textNode, start + j);
          const subRangeReact = subRange.getBoundingClientRect();
          if (
            Math.round(subRangeReact.height) === Math.round(this.lineHeight)
          ) {
            if (j !== 1) this.highlight.pop();
            j++;
          } else {
            i = j - 1;
          }
          this.highlight.push(this.calRectInfo(subRangeReact));
        }
        // 多行的情况
      }
    },
    calRectInfo(rangeReact) {
      let rectInfo = {};
      rectInfo.width = rangeReact.width;
      rectInfo.height = rangeReact.height;
      rectInfo.left = rangeReact.left - this.wrapperInfo.left;
      rectInfo.top = rangeReact.top - this.wrapperInfo.top;
      rectInfo.index = this.sign;
      return rectInfo;
    },
  },
};
</script>
<style>
.fs-highlight {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
.doc-tag {
  position: absolute;
  background-color: rgba(255, 198, 10, 0.5);
  pointer-events: none;
  z-index: 1;
}
.tag-active {
  background-color: rgba(245, 74, 69, 0.5);
}
</style>