var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.source.hidden || _vm.isVirt
    ? _c(_vm.blockComponent[_vm.source.type] || _vm.unknownBlock, {
        tag: "component",
        attrs: {
          children: _vm.source,
          index: _vm.index,
          fatherType: _vm.source.type,
          prevChild: _vm.prevChild || _vm.sources[_vm.index - 1],
          filter: _vm.filter,
          hideRowCells: _vm.hideRowCells,
          crspan: _vm.crspan,
          fatherBlockId: _vm.fatherBlockId,
          level: _vm.level + 1,
          olIndex: _vm.olIndex,
          childLen: _vm.childLen,
          showMask: _vm.showMask,
        },
        scopedSlots: _vm._u(
          [
            _vm.source.childrens && _vm.source.childrens.length > 0
              ? {
                  key: "default",
                  fn: function (slotProps) {
                    return [
                      _vm._l(_vm.source.childrens, function (child, index) {
                        return [
                          _c("page-main", {
                            key: child.blockId,
                            attrs: {
                              filter: slotProps.filter,
                              hideRowCells: slotProps.hideRowCells,
                              crspan: slotProps.crspan,
                              childLen: slotProps.childLen,
                              source: child,
                              index: index,
                              sources: _vm.source.childrens,
                              fatherType: _vm.source.type,
                              fatherBlockId: _vm.source.blockId,
                              level: slotProps.level,
                              olIndex: slotProps.olIndex,
                              prevChild:
                                index - 1 >= 0
                                  ? _vm.source.childrens[index - 1]
                                  : null,
                            },
                          }),
                        ]
                      }),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }