var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c(
        "div",
        {
          staticClass: "block docx-table-block",
          attrs: { id: `table-box-${_vm.children.blockId}` },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "docx-block-loading-container" }, [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "scrollable-wrapper",
                  staticStyle: { left: "0px" },
                },
                [
                  _c("div", { staticClass: "scrollable-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "scrollable-item",
                        on: { click: _vm.previewTable },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "table-block j-table-block" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "content-scroller",
                                staticStyle: {
                                  "overflow-y": "hidden",
                                  "margin-top": "unset",
                                  "max-width": "100%",
                                },
                              },
                              [
                                _c("table", { staticClass: "table" }, [
                                  _c(
                                    "colgroup",
                                    _vm._l(
                                      _vm.column_width,
                                      function (w, index) {
                                        return _c("col", {
                                          key: index,
                                          attrs: {
                                            width: _vm.gettableItemW(w),
                                          },
                                        })
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.row_size, function (r, index) {
                                      return _c(
                                        "tr",
                                        { key: index },
                                        [
                                          _vm._l(
                                            _vm.children.childrens,
                                            function (child, i) {
                                              return [
                                                _vm
                                                  .getCells(index)
                                                  .some(
                                                    (item) =>
                                                      item == child.blockId
                                                  )
                                                  ? _c("tableCellBlock", {
                                                      attrs: {
                                                        crspan:
                                                          _vm.getCR(index),
                                                        hideRowCells:
                                                          _vm.hideRowCells,
                                                        children: child,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.showPreview
            ? _c("v-perviewimage", {
                attrs: { previewSrcList: [_vm.imgUrl], curUrl: _vm.imgUrl },
                on: { close: _vm.closePreview },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }