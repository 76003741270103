<template>
  <div v-if="children" class="block docx-divider-block">
    <div class="divider-block__container j-drag-cls">
      <div class="divider-block__content"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "line-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  data() {
    return{
        
    }
  },
};
</script>
<style >
.divider-block__container {
  padding: 12px 0;
}
.divider-block__content {
  height: 1px;
  background-color: var(--line-divider-default);
}
</style>