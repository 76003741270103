<template>
<div id="app">
    <div v-show="$bus.ving.length" class="page-loading-container">
        <div class="page-loading" >{{ loadingMsg }}</div>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
</div>
</template>

<script>
export default {
    computed: {
        loadingMsg() {
            const item = this.$bus.ving[0];
            return typeof item === 'string' ? item : '加载中…';
        },
    },
}
</script>
<style lang="less">
@import "./common/less/common.less";
@import "./common/less/loading.less";
</style>
<style lang="less" scoped>
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
