export default function toast(html, time, unique = false) {
    if(unique){
        let nodes = document.querySelectorAll('.v-toast')
        if([...nodes].some((node) => {
            return node.innerHTML === html
        })){
            return false
        }
    }
    let toast = $.dom.create('div', { class: 'v-toast' });

    toast.innerHTML = html;
    document.body.appendChild(toast);
    setTimeout(function() {
        toast.classList.add('active');
    }, 100);
    setTimeout(function() {
        toast.classList.remove("active");
    }, time || 3600);
    setTimeout(function() {
        toast.parentNode.removeChild(toast);
    }, (time || 3600) + 400);
}
