var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c("div", { staticClass: "block docx-grid-block" }, [
        _c(
          "div",
          {
            staticClass: "grid-block j-grid-block grid-focus",
            class: _vm.isMobile ? "grid-vertical" : "grid-horizontal",
            staticStyle: { "border-radius": "8px" },
          },
          [
            _c(
              "div",
              { staticClass: "render-unit-wrapper grid-render-unit" },
              [
                _vm._t("default", null, {
                  childLen: _vm.children.childrens.length,
                }),
              ],
              2
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }