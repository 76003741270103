var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c(
        "div",
        {
          staticClass: "block docx-heading4-block",
          class: [
            `block-${_vm.children.blockId}`,
            _vm.children.blockStyles && _vm.children.blockStyles.textBackground
              ? "background"
              : "",
          ],
        },
        [
          _c(
            "div",
            { staticClass: "heading-content", class: _vm.pageStyle },
            [
              _c("v-spantext", {
                attrs: { elements: _vm.elements, children: _vm.children },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [_vm._t("default")], 2),
          _vm._v(" "),
          _c("v-highlight", { attrs: { blockId: _vm.children.blockId } }),
          _vm._v(" "),
          _vm.children.blockStyles && _vm.children.blockStyles.textBackground
            ? _c("v-blockbackground", {
                attrs: {
                  backgroundColor: _vm.children.blockStyles.textBackground,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }