import vway from 'ether-vway';
import { encrypt } from './encryption/index';

const { axios } = vway;
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

import { hasToken, canPreLogin, preLogin } from './wx-base-login';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.baseURL = '/api';

axios.interceptors.request.use(config => {
  // 跨域时不作处理
  if (/^http/i.test(config.url)) return config;
  config.cancelToken = source.token;

  // 白名单下减少登陆跳转流程 先注释，router.beforeEach里写了
  // if (!hasToken() && canPreLogin() && config.url != '/site/stat') {
  //   // if (true && config.url != '/site/stat') {
  //   source.cancel('Operation canceled by the user.' + config.url);
  //   preLogin();
  //   return config;
  // }

  config.params = Object.assign({}, config.params, $.getUtms());
  for (let [key, value] of Object.entries(config.params)) {
    if (/function/.test(value)) delete config.params[key];
  }
  let pulltime =  config.pulltime || 10
  config.timeout =  1000 * pulltime;
  // 计算 x-token
  let options = {
    method: config.method,
    uri: config.baseURL + config.url,
    params: config.params,
    data: config.data
  };
  config.headers.common['X-token-1'] = encrypt.getAuthKey(vway.ls.getGlobal('TOKEN'));
  config.headers.common['X-token-2'] = encrypt.getURIHashString(options);
  config.headers.common['X-request-by'] =  `fe-${ProjectVersion}`;
  // 默认是有loading message默认为布尔true
  config.loading = config.loading === undefined ? { show: true, message: '加载中…' } : config.loading;
  config.loading.show && vway.bus.ving.push(config.loading.message);
  return config;
}, error => {
  alert('请求错误, 请重试');
  return Promise.reject(error);
});
const codeUrls = ["/api/feishu/get-doc-blocks"]//直接返回data 无需处理
axios.interceptors.response.use((res) => {
  res.config.loading && res.config.loading.show && vway.bus.ving.shift();
  if (res.data.code && res.data.message && res.data.code != 1011) $.ui.toast(res.data.message);
  switch (res.data.code) {
  case 0:
    return codeUrls.indexOf(res.config.url) > -1 ? Promise.resolve(res.data.data) : Promise.resolve(mapObj(res.data.data));
    break;
    // 未登录
  case 10:
    vway.ls.setGlobal('TOKEN', '', -1);
    vway.ls.setGlobal('u', '', -1);
    // 白名单下减少登陆跳转流程 先注释，router.beforeEach里写了
    // if (canPreLogin()) return preLogin();
    const query = vway.qs.parse(location.search.slice(1));
    delete query.token;
    location.replace('/login?' + vway.qs.stringify({
      after_login: location.pathname + '?' + vway.qs.stringify(query),
    }));
    break;
    // 未绑定手机号
  case 15:
    vway.ls.setGlobal('TOKEN', '', -1);
    vway.ls.setGlobal('u', '', -1);
    const url = res.data.data.verifyUrl;
    location.replace(
      url + (~url.indexOf('?') ? '&' : '?') +
      vway.qs.stringify({
        gotoURL: location.href
      })
    );
    break;
  //权限不足
  case 16:
    let redirect = res.data.data.url
    setTimeout(() =>{
      redirect && location.replace(redirect);
    },1000)
    break;
    // 未填写个人信息
  case 1515:
    location.replace(
      '/profile/edit?' + vway.qs.stringify({
        gotoURL: location.pathname + location.search,
      })
    );
    break;
    // 您请求的页面不存在
  case 1000:
    // 该课程已被删除
  case 1001:
    lsGlobal.setSync('errMsg', res.data.message);
    location.replace('/forbidden');
    // 未购买该课程
  case 1005:
    break;
  default:
    break;
  }
  return Promise.reject({
    type: 'api',
    body: res.data,
  });
}, (error) => {
  if (axios.isCancel(error)) {
    error.type = 'cancel';
    // 这里如果不return error就会出现反复触发这个错误的情况
    return error;
  }
  error.config && error.config.loading && error.config.loading.show && vway.bus.ving.shift();
  if (!(error.request.readyState == 4 && error.request.status == 0)) {
    $.ui.toast('网络异常，请稍候再试', 3600, true);
    $.jsLogger.error(error.response, 'api');
  }else{
    if(error.message.indexOf('timeout') > -1){
      $.ui.toast('网络不佳，请稍候再试', 3600, true);
    }
  }
  return Promise.reject({
    type: 'network'
  });
});

/*
 * 封装全局普通ajax请求
 * 因为封装时间较晚 一些页面未使用该方法
 **/
vway.request = function (httpOptions, suc, fail) {
  return axios.request(httpOptions).then(suc).catch(err => {
    fail && typeof fail === 'function' && fail(err);
    vway.apiCatch(err);
  });
}

/*
 * 全局api请求，遍历返回的数据，替换一些资源地址为cdn的url
 *
 **/
function mapObj(obj) {
  let keys = Object.keys(obj);
  let value;
  for (let key of keys) {
    value = obj[key];
    if (typeof value == 'string') {
      obj[key] = replaceMaterialUrl(obj[key]);
    } else if (isObj(value)) {
      obj[key] = mapObj(value);
    }
  }
  return obj;
}

function isObj(obj) {
  return Object.prototype.toString.call(obj) == '[object Object]';
}

function replaceMaterialUrl(str) {
  let reg = /https\:\/\/sso-yt.ethercap.com\/upload/ig;
  if (!reg.test(str)) return str;
  return str.replace(reg, 'https://cdn.yitang.top/upload');
}
