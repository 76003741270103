<template>
  <div
    
    class="block docx-file-block is-in-video-v2"
    :class="`item-${children.blockId}`"
  >
    <template v-if="showFileDIv">
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
    <div contenteditable="false">
      <div class="docx-file-block-container docx-view-type-Preview">
        <div class="preview-card-header">
          <div
            class="preview-card-header__mask preview-card-header__mask-video"
          ></div>
          <div class="preview-card-header__container">
            <svg width="18" height="18" viewBox="0 0 32 32">
              <defs>
                <path
                  d="M1.5 0h14.086a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V26.5a1.5 1.5 0 01-1.5 1.5h-19A1.5 1.5 0 010 26.5v-25A1.5 1.5 0 011.5 0z"
                  id="icon_file_video_nor_svg__a"
                ></path>
                <path
                  d="M16.293.293l5.414 5.414A1 1 0 0121.91 6H17.5A1.5 1.5 0 0116 4.5V.09a1 1 0 01.293.203z"
                  id="icon_file_video_nor_svg__b"
                ></path>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <g transform="translate(5 2)">
                  <use
                    fill="#3370FF"
                    xlink:href="#icon_file_video_nor_svg__a"
                  ></use>
                  <use
                    fill="#245BDB"
                    xlink:href="#icon_file_video_nor_svg__b"
                  ></use>
                </g>
                <path d="M7 11h16.649v16.649H7z"></path>
                <path
                  d="M10.757 15h7.486c.383 0 .7.285.75.654l.007.103v7.486c0 .383-.285.7-.654.75l-.103.007h-7.486a.757.757 0 01-.75-.654L10 23.243v-7.486c0-.383.285-.7.654-.75l.103-.007h7.486zm11.819 1.792a.568.568 0 01.075.282v4.854a.568.568 0 01-.85.493l-1.799-1.028v-3.784l1.8-1.028a.568.568 0 01.774.211zM13.1 16.5h-1.2a.4.4 0 00-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 00.4-.4v-1.2a.4.4 0 00-.4-.4z"
                  fill="#FFF"
                ></path>
              </g></svg
            ><span class="preview-card-header__container-title">{{
              children.blockAttr.file.name.slice(0, -4)
            }}</span>
          </div>
        </div>
        <video
          controls
          playsinline="true"
          x5-playsinline="true"
          webkit-playsinline="true"
          mediatype="video"
          data-index="-1"
          :id="`video-${children.blockId}`"
          :src="videoUrl"
        ></video>
      </div>
    </div>
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
    </template>
    <div class="error-video-tip" v-else>
      视频仅支持MP4格式
    </div>
  </div>
</template>
<script>
export default {
  name: "file-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  computed: {
    showFileDIv() {
      return this.children.blockAttr.file.name.indexOf(".mp4") > -1;
    },
    videoUrl() {
      return `/api/feishu/media/${this.children.blockAttr.file.token}`;
    },
  },
};
</script>
<style>
.is-in-video-v2 .docx-view-type-Preview {
  width: 100%;
}
.docx-file-block-container {
  border-radius: 8px;
  position: relative;
}

.docx-file-block-container video {
  width: 100%;
  border-radius: 8px;
}
.preview-card-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: block;
  opacity: 0;
  transition: all 0.2s ease-out;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 12px;
  z-index: 1;
}
.preview-card-header__mask.preview-card-header__mask-video {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 8px 8px 0 0;
}

.preview-card-header__container {
  display: flex;
  align-items: center;
}
.preview-card-header__container-title {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.docx-file-block-container:hover .preview-card-header {
  opacity: 1;
}
.error-video-tip{
  color: red;
  font-size: 14px;
  text-align: center;
}
</style>