import * as bekit from 'bekit';
const { Url, Ua } = bekit.helper;
function preventTheDefault (e) {
    if (e.cancelable) {
        e.preventDefault()
    }
}
class ContainerOnlyScroll {
    _startY= null
    _canMoveStatus = 'init' // 主要是解决连续不抬起手指会出现两个方向的滚动的问题
    constainers = []
    constructor (elements) {
        this.constainers = elements
        this._handleStart = this._handleStart.bind(this)
        this._handleTouchMove = this._handleTouchMove.bind(this)
    }

    _preventMove (e) {
        preventTheDefault(e)
        this._canMoveStatus === 'init' && (this._canMoveStatus = 'off')
    }

    _handleStart (e) {
        this._startY = e.targetTouches[0].clientY
        this._canMoveStatus = 'init'
    }

    _handleTouchMove (e) {
        e.stopPropagation()
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        const currentY = e.targetTouches[0].clientY
        // 为off代表本次触摸第一次引起的move是被阻止了，转移了浏览器的滚动目标对象了，所以直接禁用滚动了
        if (this._canMoveStatus === 'off') {
            preventTheDefault(e)
            return
        }
        // 如果是向下滑动手势，即要查看上方内容时
        if (currentY - this._startY > 0) {
            // 此时刚好到达弹层容器顶部，继续滑动就阻止默认的滚动事件了
            if (scrollTop === 0) {
                this._preventMove(e)
            }
        // 否则是向下滑动手势，查看下方内容时，此时刚好到达弹层容器底部，继续滑动就阻止默认的滚动事件了
        } else if (scrollHeight - clientHeight - scrollTop < 1) {
            this._preventMove(e)
        } else {
            this._canMoveStatus = 'on'
        }
    }

    on () {
        document.documentElement.addEventListener('touchmove', preventTheDefault, false)
        this.constainers.forEach(element => {
            element.addEventListener('touchstart', this._handleStart)
            element.addEventListener('touchmove', this._handleTouchMove, {passive: false})
        })
    }

    off () {
        document.documentElement.removeEventListener('touchmove', preventTheDefault)
        this.constainers.forEach(element => {
            element.removeEventListener('touchstart', this._handleStart)
            element.removeEventListener('touchmove', this._handleTouchMove)
        })
        this._startY = null
    }
}
export default {
    setCouponList(arr) {
        let map = {}
        for (let i = 0; i < arr.length; i++) {
            let hashstr = ''
            for (const key in arr[i]) {
                if (key != 'id' && key != 'outerId') {
                    hashstr += arr[i][key]
                }
            }
            if (map[hashstr] != undefined) {
                map[hashstr].num++
            } else {
                arr[i].num = 1
                map[hashstr] = arr[i]
            }
        }
        return Object.values(map);
    },
    homeworkPageData(v) {
        const data = {
            homework: {
                name: 'homework',
                title: '作业内容',
                template: 'v-homework-rule',
                modalTitle: '作业提交后不可修改，确认提交？',
                submitUrl: '/homework/submit',
                message: '作业加载中…',
                id: 'lessonId',
                getUrl: "/homework",
            },
            activity: {
                name: 'activity',
                title: '活动报名',
                template: 'v-homework-activity-rule',
                modalTitle: '活动问卷提交后不可修改，确认提交？',
                submitUrl: '/homework/submit-activity-survey',
                message: '活动加载中…',
                id: 'activityId',
                getUrl: "/homework/activity-survey",
            }
        }
        return data[v]
    },
    format(date, pattern) {
        const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            weekcn = ["日", "一", "二", "三", "四", "五", "六"];
        let fo = {
            yyyy: date.getFullYear(),
            M: date.getMonth() + 1,
            d: date.getDate(),
            Day: date.getDay(),
            h: date.getHours(),
            m: date.getMinutes(),
            s: date.getSeconds(),
        };
        const items = {
            yy: fo.yyyy % 100,
            MM: Math.floor(fo.M / 10).toString() + fo.M % 10,
            dd: Math.floor(fo.d / 10).toString() + fo.d % 10,
            DD: week[fo.Day],
            DC: weekcn[fo.Day],
            D: week[fo.Day].slice(0, 3),
            hh: Math.floor(fo.h / 10).toString() + fo.h % 10,
            mm: Math.floor(fo.m / 10).toString() + fo.m % 10,
            ss: Math.floor(fo.s / 10).toString() + fo.s % 10,
        };
        Object.keys(items)
            .forEach(key => {
                fo[key] = items[key];
            });
        return pattern.replace(/(yyyy|yy|MM|M|dd|d|DD|DC|D|hh|h|mm|m|ss|s)/g, function (mat, item) {
            return fo[item];
        });
    },
    countdown(fn, ddl, interval = 1000) {
        const timer = {
            destroy,
            isDestroyed: false,
        };
        // 先执行一遍，再执行定时器
        set();
        const intervalId = setInterval(set, interval);

        function set() {
            let milliseconds = ddl - Date.now();
            if (milliseconds < 0) {
                destroy();
            } else {
                fn(milliseconds);
            }
        }

        function destroy() {
            clearInterval(intervalId);
            timer.isDestroyed = true;
        }
        return timer;
    },
    formatTimeDiff(milliseconds, pattern = ['d天', 'h小时', 'm分'], options) {
        if (typeof pattern === 'string') pattern = [pattern];
        options = Object.assign({
            omit: true,
            join: true,
            joinChar: ' ',
            returnMap: false,
        }, options);
        const scale = [
            ['d', 1000 * 60 * 60 * 24],
            ['h', 1000 * 60 * 60],
            ['m', 1000 * 60],
            ['s', 1000],
            ['S', 1],
        ];
        const keys = scale.map(i => i[0]);
        const r = new RegExp("(" + keys.join("|") + ")");
        let fo = {};
        pattern.forEach(i => {
            fo[i.match(r)[0]] = {
                value: 0,
                pattern: i,
                format: '',
            };
        });
        scale.forEach(i => {
            let key = i[0];
            if (fo[key] === undefined) return;
            let val = Math.floor(milliseconds / i[1]);
            fo[key].value = val;
            milliseconds -= val * i[1];
        });
        let flag = false;
        let res = pattern.reduce((sum, cur) => {
            let key = cur.match(r)[0];
            let val = cur.replace(r, fo[key].value);
            let unit = cur.replace(r, '');
            fo[key].format = val;
            fo[key].unit = unit;
            if (options.omit && !flag && fo[key].value === 0) return sum;
            flag = true;
            return sum.concat([val]);
        }, []);
        if (options.returnMap) return fo;
        if (options.join) res = res.join(options.joinChar);
        return res;
    },
    // 节流函数
    throttle(fn, interval = 200) {
        let firstTime = true,
            timer;
        return function () {
            if (timer) return;
            let _this = this;
            if (!firstTime) {
                fn.apply(_this, arguments);
                return firstTime = false;
            }
            timer = setTimeout(() => {
                clearTimeout(timer);
                timer = null;
                fn.apply(_this, arguments);
            }, interval);
        }
    },
    //防抖
    debounce(fn, delay = 1000) {
        let timer = null;
        return function () {
            if (timer) clearTimeout(timer)

            timer = setTimeout(() => {
                fn.apply(this, arguments)
                timer = null;
            }, delay)
        }
    },
    getRandomNum(length, radix = 10) {
        let result = '';
        for (let i = 0; i < length; i++) {
            result += Math.floor(Math.random() * radix).toString(radix);
        }
        return result;
    },
    urlAddHash(url, key = 't') {
        let timestamp = +new Date(),
            urlObj = new Url(url);
        return urlObj.replaceParams({
            [key]: timestamp
        }).toString();
    },
    // 兼容安卓微信webview的reload()失效问题
    reload() {
        if (Ua.isAndroid()) return location.href = this.urlAddHash(location.href);
        return location.reload();
    },
    // 解决vue-router 默认的?from=a&from=b的解析问题
    getRouteQuery(param = '') {
        let params = new Url(location.href).params || {}
        if (param) {
            return params[param] || '';
        } else {
            return params;
        }
    },
    // 处理数据
    getTextNode(arr) {
        let childNodes = [];
        if (arr) {
            arr.forEach(it => {
                if (it.nodeName == "IMG") {
                    childNodes.push(it.setURL || it.src);
                } else if (it.nodeName == "OL" || it.nodeName == "UL") {
                    childNodes.push(this.getULOLdTextNodes(it.innerHTML, it.nodeName, 0));
                } else {
                    let outerText = it.outerText || ''
                    childNodes.push(outerText.replace(/ /g, ""));
                }
            });
            let str = childNodes.join("\n").replace(/<\/?.+?>/g, "");
            return str;
        } else {
            return ''
        }
    },
    // 处理有序和无序
    getULOLdTextNodes(innerHTML, flag, rep) {
        let html = innerHTML.replace("/<" + flag + ">/g", "").replace(/ /g, "")
        let className = rep == 0 ? '' : `class="ql-indent-${rep}"`
        let htmlArr = html.split(`<li${className}>`);
        htmlArr = htmlArr.filter((h) => h)
        let str = ``
        htmlArr.forEach((itemStr, index) => {
            if (itemStr) {
                let regex = /<liclass="ql-indent-(\d+)">/i;  
                let match = itemStr.match(/(.*?)<\/li>/)
                let target = match ? match[1] : ''
                let surstr = itemStr
                if(!target.match(regex)){
                    target = flag === "OL" ? (index + 1) + "." + target : "·" + target
                    str += ('>>').repeat(rep + 1) + ' ' + target + '\n'
                    surstr = itemStr.replace(/(.*?)<\/li>/, '')
                }
                let indexMatch = surstr.match(regex);  
                if(indexMatch){
                    str += this.getULOLdTextNodes(surstr, flag, Number(indexMatch[1]))
                }
            }
        })
        return str
    },
    typeTOString(target) {
        return Object.prototype.toString.call(target)
    },
    isArray(target) {
        return Array.isArray(target)
    },
    deepMerge(target, source) {
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                // if (
                //   target.hasOwnProperty(key) &&
                //   typeof target[key] === "object" &&
                //   typeof source[key] === "object"
                // ) {
                //   this.deepMerge(target[key], source[key]);
                // } else {
                target[key] = source[key];
                // }
            }
        }
        return target;
    },
    parsePayConfig(data) {
        let orderId = data.orderId, payUrl = data.payUrl
        if (orderId !== undefined) {
            return {
                url: payUrl,
                orderId
            }
        }
        if (!payUrl) {
            return {
                url: '',
                orderId: 0
            }
        }
        if (!(/\/pay\/index/ig).test(payUrl)) {
            return {
                url: payUrl,
                orderId: 0
            }
        }
        orderId = ~~(new Url(payUrl)).params.id;
        return {
            url: payUrl,
            orderId
        }
    },
    // 复制文本内容方法一
    copyContent(content, success = () => {}, fail = () => {}) {
        // 复制结果
        let copyResult = true
        // 设置想要复制的文本内容
        const text = content || '';
        // 判断是否支持clipboard方式
        if (!!window.navigator.clipboard) {
            // 利用clipboard将文本写入剪贴板（这是一个异步promise）
            window.navigator.clipboard.writeText(text).then((res) => {
                // console.log('复制成功');
                success()
                // 返回复制操作的最终结果
                return copyResult;
            }).catch((err) => {
                // console.log('复制失败--采取第二种复制方案', err);
                // clipboard方式复制失败 则采用document.execCommand()方式进行尝试
                copyContent2(text, success, fail)
            })
        } else {
            // 不支持clipboard方式 则采用document.execCommand()方式
            copyContent2(text, success, fail)
        }
    },
    // 复制文本内容方法二
    copyContent2(text, success, fail) {
        // 复制结果
        let copyResult = true
        // 创建一个input元素
        let inputDom = document.createElement('textarea');
        // 设置为只读 防止移动端手机上弹出软键盘  
        inputDom.setAttribute('readonly', 'readonly');
        // 给input元素赋值
        inputDom.value = text;
        // 将创建的input添加到body
        document.body.appendChild(inputDom);
        // 选中input元素的内容
        inputDom.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签中的内容）
        // Input要在正常的编辑状态下原生复制方法才会生效
        const result = document.execCommand('copy')
        // 判断是否复制成功
        if (result) {
            // console.log('复制成功');
            success()
        } else {
            // console.log('复制失败');
            fail()
            copyResult = false
        }
        // 复制操作后再将构造的标签 移除
        document.body.removeChild(inputDom);
        // 返回复制操作的最终结果
        return copyResult;
    },
    regScapeStr (str) {
        return str.replace(/[\-\/\\\^\$\*\+\?\.\(\)\|\[\]\{\}]/g, '\\$&');
    },
    scrollOnly(target){
        let elements = Array.isArray(target) ? target : [target]
        elements = elements.filter(item => item)
        const constainerScroll = new ContainerOnlyScroll(elements)
        constainerScroll.on()
        return constainerScroll
    },
    handlerCDN(sourceImg){
        const cdnBase =  "https://cdn.yitang.top/upload/ether-public/yitang/"
        if(typeof sourceImg === "string"){
            return cdnBase + sourceImg
        }
        for (let key in sourceImg) {
            if (sourceImg.hasOwnProperty(key)) {
              sourceImg[key] = cdnBase + sourceImg[key];
            }
        }
    },
    getRecordNumber(host){
        let hostname = host || window.location.host
        const recordNumber = [
            {
                host: 'yitang.top',
                number: '津ICP备20005888号-1',
                link: 'https://beian.miit.gov.cn/#/Integrated/index'
            },
            {
                host: 'yt259.com',
                number: '京ICP备2022035291号-1',
                link: 'https://beian.miit.gov.cn/'
            }
        ]
        for(let i=0; i < recordNumber.length; i++){
            if(hostname.includes(recordNumber[i].host)){
                return recordNumber[i]
            }
        }
        return {number: '', link: ''}
    }
};