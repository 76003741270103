var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$bus.ving.length,
              expression: "$bus.ving.length",
            },
          ],
          staticClass: "page-loading-container",
        },
        [
          _c("div", { staticClass: "page-loading" }, [
            _vm._v(_vm._s(_vm.loadingMsg)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "keep-alive",
        [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
      _vm._v(" "),
      !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }