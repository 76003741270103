import unknownBlock from '../components/unknown-block.vue' //未知 Block
import pageBlock from '../components/page-block.vue' //页面 Block
import textBlock from '../components/text-block.vue' //文本 Block
import h1Block from '../components/h1-block.vue' //标题 1 Block
import h2Block from '../components/h2-block.vue' //标题 2 Block
import h3Block from '../components/h3-block.vue' //标题 3 Block
import h4Block from '../components/h4-block.vue' //标题 4 Block
import h5Block from '../components/h5-block.vue' //标题 5 Block
import ulBlock from '../components/ul-block.vue' //无序列表 Block
import olBlock from '../components/ol-block.vue' //有序列表 Block
import codeBlock from '../components/code-block.vue' //有序列表 Block
import quoteBlock from '../components/quote-block.vue' //引用 Block
import lightBlock from '../components/light-block.vue' //高亮块 Block
import lineBlock from '../components/line-block.vue' //高亮块 Block
import fileBlock from '../components/file-block.vue' //文件块 Block
import gridBlock from '../components/grid-block.vue' //分栏  Block
import columnBlock from '../components/column-block.vue' //分栏列 Block
import imgBlock from '../components/img-block.vue' //图片 Block
import tableBlock from '../components/table-block.vue' //表格 Block
import tableCellBlock from '../components/table-cell-block.vue' //表格单元格 Block
import viewBlock from '../components/view-block.vue' //视图 Block
import quoteContainerBlock from '../components/quote-container-block.vue' //引用容器 Block
import syncedReferenceBlock from '../components/synced-reference-block.vue' //未知引用 Block
const blockHeader = [3, 4, 5, 6]
const foldHeader = [3, 4, 5]
const blockComponent = {
    '-1': unknownBlock,
    '1': pageBlock,
    '2': textBlock,
    '3': h1Block,
    '4': h2Block,
    '5': h3Block,
    '6': h4Block,
    '7': h5Block,
    '8': h5Block,
    '9': h5Block,
    '10': h5Block,
    '11': h5Block,
    '12': ulBlock,
    '13': olBlock,
    '14': codeBlock,
    '15': quoteBlock,
    '19': lightBlock,
    '22': lineBlock,
    '23': fileBlock,
    '24': gridBlock,
    '25': columnBlock,
    '27': imgBlock,
    '31': tableBlock,
    '32': tableCellBlock,
    '33': viewBlock,
    '34': quoteContainerBlock,
    '999': syncedReferenceBlock
}
const blockKeys ={
    '1': 'page',
    '2': 'text',
    '3': 'heading1',
    '4': 'heading2',
    '5': 'heading3',
    '6': 'heading4',
    '7': 'heading5',
    '8': 'heading6',
    '9': 'heading7',
    '10': 'heading8',
    '11': 'heading9',
    '12': 'bullet',
    '13': 'ordered',
    '14': 'code',
    '15': 'quote',
    '19': 'callout',
    '22': 'divider',
    '23': 'file',
    '24': 'grid',
    '25': 'grid_column',
    '27': 'image',
    '31': 'table',
    '32': 'table_cell',
    '33': 'view',
    '34': 'quote_container'
}
const codeLanguage = {
    "1": "PlainText",
    "2": "ABAP",
    "3": "Ada",
    "4": "Apache",
    "5": "Apex",
    "6": "Assembly",
    "7": "Bash",
    "8": "CSharp",
    "9": "C++",
    "10": "C",
    "11": "COBOL",
    "12": "CSS",
    "13": "CoffeeScript",
    "14": "D",
    "15": "Dart",
    "16": "Delphi",
    "17": "Django",
    "18": "Dockerfile",
    "19": "Erlang",
    "20": "Fortran",
    "21": "FoxPro",
    "22": "Go",
    "23": "Groovy",
    "24": "HTML",
    "25": "HTMLBars",
    "26": "HTTP",
    "27": "Haskell",
    "28": "JSON",
    "29": "Java",
    "30": "JavaScript",
    "31": "Julia",
    "32": "Kotlin",
    "33": "LateX",
    "34": "Lisp",
    "35": "Logo",
    "36": "Lua",
    "37": "MATLAB",
    "38": "Makefile",
    "39": "Markdown",
    "40": "Nginx",
    "41": "Objective",
    "42": "OpenEdgeABL",
    "43": "PHP",
    "44": "Perl",
    "45": "PostScript",
    "46": "Power",
    "47": "Prolog",
    "48": "ProtoBuf",
    "49": "Python",
    "50": "R",
    "51": "RPG",
    "52": "Ruby",
    "53": "Rust",
    "54": "SAS",
    "55": "SCSS",
    "56": "SQL",
    "57": "Scala",
    "58": "Scheme",
    "59": "Scratch",
    "60": "Shell",
    "61": "Swift",
    "62": "Thrift",
    "63": "TypeScript",
    "64": "VBScript",
    "65": "Visual",
    "66": "XML",
    "67": "YAML",
    "68": "CMake",
    "69": "Diff",
    "70": "Gherkin",
    "71": "GraphQL",
    "72": "OpenGL Shading Language",
    "73": "Properties",
    "74": "Solidity",
    "75": "TOML",
}
function resizecContentOffset(handler) {
    window.addEventListener('resize', handler, false)
}

function createCellPos(n) {
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while (n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
}

function romanize(num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["", "c", "cc", "ccc", "cd", "d", "dc", "dcc", "dccc", "cm",
            "", "x", "xx", "xxx", "xl", "l", "lx", "lxx", "lxxx", "xc",
            "", "i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix"
        ],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

function isUrlRegExp(content) {
    try {
        let url = new URL(content);
        //如果有空格直接false
        if(url.origin === 'null') return false
        if(url.href.indexOf("%20") > -1){
            return false
        }
        return true;
    } catch (_) {
        return false;
    }
}

function openUrl(link, content, e) {
    if (link && link.url) {
        e && e.stopPropagation()
        window.open(decodeURIComponent(link.url), "_blank");
    }
    if (isUrlRegExp(content)) {
        e && e.stopPropagation()
        window.open(decodeURIComponent(content), "_blank");
    }
}
function getRandomIndex(n ,index) {
    const array = Array.from({ length: n }, (_, index) => index);
    var currentIndex = array.length;
    var temporaryValue, randomIndex;
 
    // While there remain elements to shuffle
    while (currentIndex !== 0) {
 
       // Pick a remaining element
       randomIndex = Math.floor(Math.random() * currentIndex);
       currentIndex -= 1;
 
       // Swap with the current element
       temporaryValue = array[currentIndex];
       array[currentIndex] = array[randomIndex];
       array[randomIndex] = temporaryValue;
    }
    if(index !== undefined && array[0] == index){
        return getRandomIndex(n, index)
    }
    // Return the first index
    return array[0];
 }
export {
    blockComponent,
    blockHeader,
    foldHeader,
    resizecContentOffset,
    createCellPos,
    romanize,
    isUrlRegExp,
    openUrl,
    getRandomIndex,
    blockKeys,
    codeLanguage
}