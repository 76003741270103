var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "background-mask",
    style: `position: absolute;
    inset: 0px;
    border-radius: 6px;
    pointer-events: none;
    background-color: ${_vm.backgroundColor};`,
    attrs: { contenteditable: "false" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }