const Default = {
  prefix: '',
  statsName: 'vway_stats',
  statIdName: 'vway_statId',
  justUpdateName: 'vway_stat_justUpdate',
  sessionIdName: 'vway_sess_id',
  // 实际上最终效果是每2s调用一次send
  updateGap: 1,
  breakPoint: 30
};
// class
class Stat {
  constructor(vway, options) {
    this.vway = vway;
    options = Object.assign({}, Default, options);
    for (let key in options) {
      this[key] = options[key];
    }
    if (!vway.ls.get(this.statsName)) vway.ls.set(this.statsName, [], false);
    setInterval(this.update.bind(this), this.updateGap * 1000);
  }
  add(type, params = {}, delay = true, route = null) {
    let $ = this.vway,
      ev = {};
    // 默认类型是click
    params.type = params.type || 'click';
    params = Object.assign({}, params, this.stuff());
    for (let key in params) {
      ev[key] = params[key];
    }
    ev.utm_type = this.prefix + type;
    // uids event;
    let statId = (ls.getSync(this.statIdName) || 0) + 1;
    ls.setSync(this.statIdName, statId);
    ev[this.statIdName] = statId;
    // other data
    ev.uids_page = ls.getSync('uids_page');
    ev.url_origin = location.origin;
    ev.url_pathname = route ? route.path : location.pathname;
    ev.url_search = route ? $.qs.stringify(route.query) : location.search.slice(1);
    ev.stat_time = $.date.format(new Date(), 'yyyy-MM-dd hh:mm:ss');
    if (delay) this.record(ev);
    else this.send(ev, true);
  }
  record(ev) {
    let $ = this.vway,
      events = ls.getSync(this.statsName);
    if (!events) {
      events = [];
    }
    events.push(ev);
    ls.setSync(this.statsName, events);
    this.update();
  }
  update() {
    let $ = this.vway,
      events = ls.getSync(this.statsName),
      justUpdate = ls.getSync(this.justUpdateName);
    if (!events) {
      events = [];
    }
    if (this.locked() || (events.length < this.breakPoint && justUpdate)) return;
    this.lock();
    this.send(events, false, () => {
      this.unlock();
    });
  }
  send(events) {}
  sent(events) {
    if (events.length) {
      let _this = this,
        allEvents = ls.getSync(this.statsName);
      let ids = events.reduce(function (pre, cur) {
        pre[cur[_this.statIdName]] = true;
        return pre;
      }, {});
      if (!allEvents) {
        allEvents = [];
      }
      allEvents = allEvents.filter(function (ev) {
        return (ev[_this.sessionIdName] == '0') && !(ev[_this.statIdName] in ids);
      });
      ls.setSync(this.statsName, allEvents);
    }
    ls.setSync(this.justUpdateName, true, this.updateGap);
  }
  stuff() {
    return {};
  }
  lock() {
    this.vway.ls.set('vway_stat_lock', true, 10);
  }
  locked() {
    return this.vway.ls.get('vway_stat_lock');
  }
  unlock() {
    this.vway.ls.set('vway_stat_lock', true, -1);
  }
  click(name, params) {
    this.add('click', OBject.assign({
      name: name
    }, params));
  }
}

const plugin = {
  install: function (vway, config) {
    vway.stat = new Stat(vway, config);
  }
};
export default plugin;