var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c(
        "div",
        {
          staticClass: "block docx-heading2-block",
          class: [
            _vm.showSwitch ? "mb-50" : "",
            `block-${_vm.children.blockId}`,
            _vm.children.blockStyles && _vm.children.blockStyles.textBackground
              ? "background"
              : "",
          ],
        },
        [
          _c(
            "div",
            { staticClass: "heading-content", class: _vm.pageStyle },
            [
              _vm.showSwitch
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right-start",
                        width: "200",
                        trigger: "click",
                      },
                      model: {
                        value: _vm.showCatalog,
                        callback: function ($$v) {
                          _vm.showCatalog = $$v
                        },
                        expression: "showCatalog",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "van-cell-group van-hairline--top-bottom",
                        },
                        _vm._l(_vm.children.childrens, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.blockId,
                              staticClass: "van-cell",
                              on: {
                                click: function ($event) {
                                  return _vm.JumpPage(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "van-cell__title" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabelText(
                                        item.blockAttr.heading3.elements
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.switchType == 2 || _vm.switchType == 3
                                ? _c(
                                    "div",
                                    { staticClass: "van-cell__value" },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _c("van-icon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.isLike(item.blockId),
                                                expression:
                                                  "isLike(item.blockId)",
                                              },
                                            ],
                                            attrs: {
                                              color: "#f56c6c",
                                              name: "like",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("van-icon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.isPass(item.blockId),
                                                expression:
                                                  "isPass(item.blockId)",
                                              },
                                            ],
                                            attrs: { name: "share" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("van-icon", {
                        staticClass: "catalog",
                        attrs: {
                          slot: "reference",
                          name: "filter-o",
                          color: "#409eff",
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-spantext", {
                attrs: { elements: _vm.elements, children: _vm.children },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.mode == 0 ? _c("div", [_vm._t("default")], 2) : _vm._e(),
          _vm._v(" "),
          _c("v-highlight", { attrs: { blockId: _vm.children.blockId } }),
          _vm._v(" "),
          _vm.mode == 0
            ? _c("toggleContent", {
                attrs: {
                  fold: _vm.children.fold,
                  blockId: _vm.children.blockId,
                  foldChild: _vm.children.foldChild,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showSwitch
            ? _c("switchBlock", {
                ref: "switchBlock",
                attrs: { fatherIndex: _vm.index, source: _vm.children },
                on: { push: _vm.tempPush },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.children.blockStyles && _vm.children.blockStyles.textBackground
            ? _c("v-blockbackground", {
                attrs: {
                  backgroundColor: _vm.children.blockStyles.textBackground,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }