<template>
  <div v-if="children" class="block docx-heading1-block" :class="[`block-${children.blockId}`, children.blockStyles && children.blockStyles.textBackground ? 'background':'']">
    <div class="heading-content" :class="pageStyle">
      <v-spantext :elements="elements" :children="children"></v-spantext>
      <toggleContent v-if="mode == 0"  :fold="children.fold" :blockId="children.blockId" :foldChild="children.foldChild"></toggleContent>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
    <div >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";
import toggleContent from "./toggle-content.vue";
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "h1-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  components: {
    toggleContent,
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
      isMobile,
      mode: this.$route.query.mode || '0',
    };
  },
  computed: {
    elements() {
      return this.children.blockAttr.heading1.elements || [];
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.heading1.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
  },
  methods: {},
};
</script>
<style >
.page-block-children .block.docx-heading1-block {
  margin-top: 26px;
  margin-bottom: 10px;
  font-size: 26px;
}
.block.docx-heading1-block .el-icon-caret-bottom{
  display: none;
}
.listitem:hover .block.docx-heading1-block .el-icon-caret-bottom{
  display: inline-block;
}
</style>