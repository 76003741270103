<template>
  <div v-if="children" class="block docx-quote-block" :class="[`block-${children.blockId}`, `item-${children.blockId}`, children.blockStyles && children.blockStyles.textBackground ? 'background':'']">
    <div class="quote-block">
      <div class="text-editor">
        <v-spantext :elements="elements" :children="children"></v-spantext>
      </div>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";
export default {
  name: "quote-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
    };
  },
  computed: {
    elements() {
      let elements = this.children.blockAttr.quote.elements || [];
      return elements
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.heading1.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
  },
  methods: {},
};
</script>
<style scoped>
.page-block-children .block.docx-quote-block {
  margin-left: 0;
}
.quote-block {
  padding-left: 10px;
}
.quote-block .text-editor {
  font-size: 16px;
  line-height: 26px;
  color: var(--text-caption);
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.quote-block:after {
  width: 2px;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: var(--udtoken-quote-bar-bg);
  border-radius: 1px;
}
</style>