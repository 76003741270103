<template       
>
  <div v-if="children" class="block docx-grid-block">
    <div
      class="grid-block j-grid-block  grid-focus"
      :class="isMobile ? 'grid-vertical': 'grid-horizontal'"
      style="border-radius: 8px"
    >
      <div class="render-unit-wrapper grid-render-unit">
        <slot :childLen="children.childrens.length"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "grid-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      isMobile,
    };
  },
  computed: {},
};
</script>
<style >
.grid-block.grid-vertical > .grid-render-unit {
  flex-direction: column;
}
.grid-block > .grid-render-unit {
  display: flex;
}
.grid-block .column-gap {
  cursor: default;
  margin: 0 6px;
  opacity: 0;
  transition: opacity 0.25s ease;
  touch-action: none;
  z-index: 1;
}
.grid-block > .grid-render-unit > .column-gap > .column-gap-inner {
  width: 100%;
  height: 100%;
  min-width: 12px;
}

.grid-block .column-gap > .column-gap-inner {
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
}
</style>