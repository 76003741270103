var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c("div", { staticClass: "block docx-quote_container-block" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "quote-container-block" }, [
          _c(
            "div",
            { staticClass: "quote-container-block-children" },
            [_vm._t("default")],
            2
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }