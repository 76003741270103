var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "v-modal flex flex-center flex-middle fade-in" },
        [
          _c(
            "div",
            { staticClass: "v-modal-dialog", style: _vm.dialogStyle },
            [
              _vm._t("header", function () {
                return [
                  _vm.title
                    ? _c("div", { staticClass: "v-modal-header" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm._t("default", function () {
                return [
                  _vm.content
                    ? _c("div", { staticClass: "v-modal-body" }, [
                        _vm._v(_vm._s(_vm.content)),
                      ])
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm._t("footer", function () {
                return [
                  _vm.showCancelButton || _vm.showConfirmButton
                    ? _c(
                        "div",
                        { staticClass: "flex flex-center v-modal-footer" },
                        [
                          _vm.showCancelButton
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "flex-item-1 btn btn-default is-round",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("cancel", $event)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.cancelText))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showConfirmButton
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "flex-item-1 btn btn-primary is-round",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("confirm", $event)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.confirmText))]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }