<template>
  <div
    contenteditable="false"
    :style="`position: absolute;
      inset: 0px;
      border-radius: 6px;
      pointer-events: none;
      background-color: ${backgroundColor};`"
    class="background-mask"
  ></div>
</template>
<script>
export default {
  name: "blockbackground",
  props: {
    backgroundColor: {
      typeof: String,
      default: "",
    },
  },
};
</script>