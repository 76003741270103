var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block docx-callout-block" }, [
    _c("div", { staticClass: "callout-block", style: _vm.blockStyle }, [
      _vm.children.blockAttr.callout.emoji_id
        ? _c(
            "div",
            {
              staticClass: "callout-emoji-container",
              attrs: { contenteditable: "false" },
            },
            [
              _c("div", { staticClass: "callout-block-emoji disabled" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "emoji-mart-emoji emoji-mart-emoji-native emoji-mart-new",
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "20px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.emojis[_vm.children.blockAttr.callout.emoji_id]
                        )
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "callout-block-children" }, [
        _c(
          "div",
          { staticClass: "callout-render-unit" },
          [_vm._t("default")],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }