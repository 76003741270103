import Vue from 'vue';
import vway from "ether-vway";
const $ = vway;
import '@vant/touch-emulator';
import Cookies from 'js-cookie'
import App from "./App.vue";
// 引入全局api封装
import "./common/http.js";
// 引入全局组件
import "./common/components.js";
// 引入全局指令
import "./common/directives.js";
// 注册UI插件
import toast from "./components/toast.js";
// 引入ledap并初始化
import './common/init-ledap.js';
// 引入element
import ElementUI from 'element-ui'; 
Vue.use(ElementUI);
//动画
import 'animate.css';
//右键菜单
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);
$.extend({
  ui: { toast }
});
//图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
//多根
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

// 全屏滚动 vue-fullpage.js
import 'fullpage.js/vendors/scrolloverflow';
import VueFullpage from 'vue-fullpage.js'
Vue.use(VueFullpage)


import * as bekit from 'bekit';
$.bekit = bekit;
window.ls = new bekit.helper.Ls();
window.lsGlobal = new bekit.helper.Ls({
  prefix: '#|g.'
});
Vue.use(bekit.plugin.vueNotice, {
  toast: { iconClass: 'yicon' }
});
// 引入微信sdk
import WxSDK from './common/wx-sdk.js';
$.WxSDK = WxSDK;
// 引入图片懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, { attempt: 1 });
// 引入util
import util from './common/util/util.js';
$.util = Object.assign($.util || {}, util);
// 判断环境：是否移动端、是否iOS
const Ua = $.bekit.helper.Ua;
let isMob = Ua.isMob();

// 采用这中方式解析Promise，否则异步加载的组件中Promise无法被解析
window.Promise = Promise;
// 和vconsole功能类似
// import eruda from 'eruda';
// eruda.init();
// 记录用户进入站点的入口url，ios下微信js-sdk验签使用
$.entryUrl = location.href.split('#')[0];
// 注册globalData作为页面之间传参的逃生舱 如回退页面传参
$.G = {};

// token
let queryObj = $.qs.parse(location.search.slice(1));
if (queryObj.token) {
  lsGlobal.setSync('TOKEN', queryObj.token);
}

// utm 参数们的初始化
let utm_content_cookie = Cookies.get('utm_content')
let utm_content = null
if(utm_content_cookie){
  utm_content = utm_content_cookie
  lsGlobal.setSync('utm_content', utm_content);
}else{
  utm_content = lsGlobal.getSync('utm_content');
  if (!utm_content) {
    utm_content = Date.now().toString(36) + Math.random().toString(36).slice(2);
    lsGlobal.setSync('utm_content', utm_content);
  }
  Cookies.set('utm_content', utm_content, { domain: '.yitang.top', path: '/', expires: 3650 });
}

// 外部 utm
// _uds是另外一个表征用户来源的字段，应后端要求与utm_source区分开
["utm_source", "utm_medium", "utm_activity", "_uds"].forEach(key => {
  if (queryObj[key]) {
    lsGlobal.setSync(key, queryObj[key]);
  }
});
let utm_media = /DealEase/i.test(navigator.userAgent) ?
  "app-web" :
  /MicroMessenger/i.test(navigator.userAgent) ?
  "weixin" :
  isMob ?
  "mobile" :
  "web";

$.getUtms = () => {
  return {
    utm_media: utm_media,
    utm_content: utm_content,
    utm_source: lsGlobal.getSync("utm_source") || "",
    utm_medium: lsGlobal.getSync("utm_medium") || "",
    utm_activity: lsGlobal.getSync("utm_activity") || "",
    _uds: lsGlobal.getSync("_uds") || ""
  };
};

// 错误监控
import jsLogger from 'ether-report-logger';
$.jsLogger = jsLogger;
$.jsLogger.init({
  params: {
    media: /MicroMessenger/i.test(navigator.userAgent) ? 'weixin' : isMob ? 'mobile' : 'web',
    source: 'yitang',
    utm_content: utm_content
  },
  logUrl: '/api/logger/logger',
  consoleOutput: true
});

Vue.config.errorHandler = function (err, vm, info) {
  JSON.stringify(err) != '{}' && (err.fullPath = vm.$route.fullPath);
  $.jsLogger.error(err);
};

$.apiCatch = function (obj = {}) {
  switch (obj.type) {
    // 状态码不为零时 已经有过提示
  case 'api':
  case 'cancel':
    // 网络请求出现错误时不处理
  case 'network':
    break;
    // 上报then中的错误
  default:
    JSON.stringify(obj) != '{}' && (obj.fullPath = location.href);
    $.jsLogger.error(obj);
    break;
  }
};

// 前端统计
import statPlugin from './common/stat/stat.js';
import configData from './config/config-data.json';
let stat = configData.stat;
$.use(statPlugin, {
  prefix: 'yitang_',
  statId: 'uids_stat',
  sessionIdName: 'uids_session',
  stuff: function () {
    return Object.assign({}, $.getUtms());
  },
  send: function (statData, direct, cb) {
    let u = lsGlobal.getSync('u');
    if (direct) {
      let img = new Image();
      statData.u = u;
      statData.host = location.host;
      // 发送的是个数组 和下面发送的格式保持一致
      img.src = location.origin + '/api' + stat.stat_url + '?' + $.qs.stringify([statData]);
      return;
    }
    // 主线为发送一个数组过去
    if (!statData.length) {
      this.sent(statData);
      cb();
      return;
    }
    $.axios.post(stat.stat_url, statData, {
      params: {
        u: u,
        host: location.host
      },
      loading: { show: false }
    }).then(() => {
      this.sent(statData);
      cb();
    }).catch(() => {
      $.apiCatch();
      cb();
    });
  }
});

Vue.config.productionTip = false;
import VirtualList from './plugins/virtual-list/src/index'
Vue.component('virtual-list', VirtualList)
// 注册全局常量
import LESSON_STATUS from '@/common/lesson-status.js';
const GLOBAL_CONST = Object.assign({}, LESSON_STATUS);
Object.defineProperty(Vue.prototype, '$G', {
  get() { return GLOBAL_CONST; }
});
//全局变量,在任意组件使用this.eventBus
Vue.prototype.$eventBus = new Vue();
import router from './router.js';
import store from './store';
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");