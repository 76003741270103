<template>
  <div
    v-if="children"
    class="block docx-table-block"
    :id="`table-box-${children.blockId}`"
  >
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
    <div class="docx-block-loading-container">
      <div>
        <div class="scrollable-wrapper" style="left: 0px">
          <div class="scrollable-container">
            <div class="scrollable-item" @click="previewTable">
              <div class="table-block j-table-block">
                <div
                  class="content-scroller"
                  style="overflow-y: hidden; margin-top: unset; max-width: 100%"
                >
                  <table class="table">
                    <colgroup>
                      <col
                        v-for="(w, index) in column_width"
                        :width="gettableItemW(w)"
                        :key="index"
                      />
                    </colgroup>
                    <tbody>
                      <tr v-for="(r, index) in row_size" :key="index">
                        <template v-for="(child, i) in children.childrens">
                          <tableCellBlock
                            v-if="
                              getCells(index).some(
                                (item) => item == child.blockId
                              )
                            "
                            :crspan="getCR(index)"
                            :hideRowCells="hideRowCells"
                            :children="child"
                          ></tableCellBlock>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
    <!-- <el-image
      v-show="showCurrent"
      class="fix-mask-image"
      :ref="`image__preview_${children.blockId}`"
      :preview-src-list="[imgUrl]"
      :src="imgUrl"
    ></el-image> -->
    <v-perviewimage
      v-if="showPreview"
      :previewSrcList="[imgUrl]"
      :curUrl="imgUrl"
      @close="closePreview"
    ></v-perviewimage>
  </div>
</template>
<script>
import * as bekit from "bekit";
import tableCellBlock from "./table-cell-block.vue";
const { Ua } = bekit.helper;
export default {
  name: "table-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  components: {
    tableCellBlock,
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      hideRowCells: [],
      showCurrent: false,
      isMobile,
      pageRect: null,
      imgUrl: "",
      showPreview: false,
      node: null,
    };
  },
  watch: {
    children: {
      handler() {
        this.hideRowCells = [];
      },
      deep: true,
    },
  },
  computed: {
    column_width() {
      return this.children.blockAttr.table.property.column_width;
    },
    row_size() {
      return this.children.blockAttr.table.property.row_size;
    },
    column_size() {
      return this.children.blockAttr.table.property.column_size;
    },
    merge_info() {
      return this.children.blockAttr.table.property.merge_info;
    },
    cells() {
      return this.children.blockAttr.table.cells;
    },
    column_width_total() {
      return this.column_width.reduce((pre, next) => {
        return pre + next;
      }, 0);
    },
  },
  mounted() {
    window.addEventListener("resize", this.getPageMain);
  },
  methods: {
    closePreview() {
      this.showPreview = false;
      this.$store.commit("setPerviewImageUrl", "");
    },
    getPageMain() {
      this.pageRect = document
        .querySelector(".page-main")
        .getBoundingClientRect();
    },
    gettableItemW(w) {
      let pageRect =
        this.pageRect ||
        document.querySelector(".page-main").getBoundingClientRect();
      if (this.isMobile || !pageRect) return w;
      let width = pageRect.width - 20;
      if (width > this.column_width_total) {
        return w;
      }
      return (width * w) / this.column_width_total;
    },
    getCells(index) {
      return this.cells.slice(
        index * this.column_size,
        index * this.column_size + this.column_size
      );
    },
    getCR(index) {
      let start = index * this.column_size;
      let end = index * this.column_size + this.column_size;
      let idsCR = {};
      for (let i = start; i < end; i++) {
        let cell = this.cells[i];
        let info = this.merge_info[i];
        for (let j = 1; j < info.col_span; j++) {
          let blockId = this.cells[i + j];
          if (this.hideRowCells.indexOf(blockId) == -1) {
            this.hideRowCells.push(blockId);
          }
        }
        for (let k = 1; k < info.row_span; k++) {
          for (let j = 0; j < info.col_span; j++) {
            let blockId = this.cells[i + k * this.column_size + j];
            if (this.hideRowCells.indexOf(blockId) == -1) {
              this.hideRowCells.push(blockId);
            }
          }
        }
        idsCR[cell] = {
          col_span: info.col_span,
          row_span: info.row_span,
        };
      }
      return idsCR;
    },
    previewTable() {
      // if (this.isMobile) return;
      if (this.imgUrl) {
        this.handlePreview();
        return;
      }
      let ele = document.getElementById(`table-box-${this.children.blockId}`);
      let _clone = ele.cloneNode(true);
      this.node = document.createElement("div");
      this.node.style.position = "fixed";
      this.node.style.top = "99999px";
      _clone.style.margin = "0px";
      this.node.className = "page-block-children";
      this.node.id = `canvas-container-html-${this.children.blockId}`;
      this.node.appendChild(_clone);
      let firstChild = document.body.firstChild;
      document.body.insertBefore(this.node, firstChild);
      html2canvas(this.node, {
        useCORS: true,
        forceUseCORS: true,
        scale: 2,
      })
        .then((canvas) => {
          _clone.remove();
          this.node.remove();
          this.imgUrl = canvas.toDataURL("image/png");
          this.handlePreview();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePreview() {
      this.showPreview = true;
      // this.$refs[`image__preview_${this.children.blockId}`].clickHandler();
      // this.previewShow();
    },
    hiddenMask() {
      this.showCurrent = false;
      this.move();
    },
    stop() {
      //滑动限制
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    move() {
      //取消滑动限制
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    previewShow() {
      this.stopAddUpNode();
      this.$nextTick(() => {
        let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
        let closeEle = document.querySelector(".el-image-viewer__close"); // 获取遮罩层关闭按钮dom
        if (!domImageView || !closeEle) {
          return;
        }
        domImageView.addEventListener("click", () => {
          this.hiddenMask();
          this.removeUpNode();
        });
        closeEle.addEventListener("click", () => {
          this.hiddenMask();
          this.removeUpNode();
        });
      });
    },
    handleEvent(e) {
      e = e || window.event;
      if ((e.wheelDelta && event.ctrlKey) || e.detail) {
        event.preventDefault();
      }
    },
    stopAddUpNode() {
      document.addEventListener("mousewheel", this.handleEvent, {
        capture: false,
        passive: false,
      });
    },
    removeUpNode() {
      document.removeEventListener("mousewheel", this.handleEvent, {
        capture: false,
        passive: false,
      });
    },
  },
};
</script>
<style >
.page-block-children .docx-table-block {
  padding-bottom: 2px;
  margin-top: 29.5px;
  margin-bottom: 24px;
}
.block .docx-block-zero-space {
  position: absolute;
  display: inline-block;
}
.docx-block-loading-container {
  position: relative;
}
.scrollable-wrapper {
  position: relative;
}
.scrollable-container {
  position: relative;
}
.scrollable-container .scrollable-item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  will-change: left;
  display: flex;
  max-width: 100%;
  overflow: auto;
}
.table-block {
  position: relative;
}
.docx-table-block .table {
  width: 50px;
  border-spacing: 0;
  word-break: break-word;
  cursor: text;
  table-layout: fixed;
  position: relative;
  border-collapse: initial;
  cursor: zoom-in;
}
.docx-table-block .text-block .text-editor {
  font-size: 14px;
  min-height: 16px;
  padding: 0 0 0 12px;
  line-height: 23px !important;
  min-height: 23px !important;
}
.docx-table-block
  .text-block
  span:not(.equation-katex-span):not(.docx-button-component) {
  font-size: 14px;
}
.docx-table-block .list-wrapper .list .text-editor {
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 22px;
  min-height: 22px;
}
.fix-mask-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
</style>