var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    {
      staticClass:
        "block table-cell-block table-cell-block-host cell-26 table-cell-content-wrapper docx-table_cell-block",
      style: {
        display:
          _vm.hideRowCells.indexOf(_vm.children.blockId) > -1 ? "none" : "",
        ..._vm.blockStyles,
      },
      attrs: {
        rowspan: _vm.crspan[_vm.children.blockId].row_span,
        colspan: _vm.crspan[_vm.children.blockId].col_span,
      },
    },
    [
      _c(
        "div",
        { staticClass: "render-unit-wrapper" },
        [
          _vm._l(_vm.children.childrens, function (child, index) {
            return [
              _c("page-main", {
                key: child.blockId,
                attrs: {
                  childLen: _vm.children.childrens.length,
                  level: -1,
                  olIndex: 0,
                  source: child,
                  index: index,
                  sources: _vm.children.childrens,
                  fatherType: _vm.children.type,
                  fatherBlockId: _vm.children.blockId,
                  prevChild:
                    index - 1 >= 0 ? _vm.children.childrens[index - 1] : null,
                },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }