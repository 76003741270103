var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "countdown-container" },
    [
      _vm._l(_vm.keys, function (i) {
        return _vm._t(
          i,
          function () {
            return [
              _c("span", { staticClass: "time-value" }, [
                _vm._v(_vm._s(_vm.handleValue(_vm.timeDiffMap[i].value))),
              ]),
              _vm.timeDiffMap[i].unit
                ? _c("span", { staticClass: "time-unit" }, [
                    _vm._v(_vm._s(_vm.timeDiffMap[i].unit)),
                  ])
                : _vm._e(),
            ]
          },
          {
            value: _vm.timeDiffMap[i].value,
            unit: _vm.timeDiffMap[i].unit,
            format: _vm.timeDiffMap[i].format,
            pattern: _vm.timeDiffMap[i].pattern,
          }
        )
      }),
      _vm._v(" "),
      _vm.showDecimal
        ? _vm._t("decimal", function () {
            return [
              _c("span", { staticClass: "time-value time-decimal" }, [
                _vm._v(_vm._s(_vm.decimal)),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }