import Vue from 'vue';

// 注册全局组件
import fetchMore from '../components/fetch-more';
import modal from '../components/modal';
import dialog from '../components/dialog';
import countdown from '../components/countdown';
import float from '../components/float';
import highlight from '../components/high-light';
import spantext from '../components/span-text';
import perviewimage from '../components/perview-image';
import blockbackground from '../components/block-background';

Vue.component('v-fetch-more', fetchMore);
Vue.component('v-modal', modal);
Vue.component('y-dialog', dialog);
Vue.component('v-countdown', countdown);
Vue.component('v-float', float);
//飞书文档组件
Vue.component('v-highlight', highlight);
Vue.component('v-spantext', spantext);
Vue.component('v-perviewimage', perviewimage);
Vue.component('v-blockbackground', blockbackground);