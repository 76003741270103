var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page-block-header",
      class: [
        `item-${_vm.children.blockId}`,
        _vm.children.blockStyles && _vm.children.blockStyles.textBackground
          ? "background"
          : "",
      ],
    },
    [
      _c(
        "div",
        { staticClass: "page-block-content", class: _vm.pageStyle },
        [
          _c("v-spantext", {
            attrs: { elements: _vm.elements, children: _vm.children },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-highlight", { attrs: { blockId: _vm.children.blockId } }),
      _vm._v(" "),
      _vm.children.blockStyles && _vm.children.blockStyles.textBackground
        ? _c("v-blockbackground", {
            attrs: { backgroundColor: _vm.children.blockStyles.textBackground },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }