<template>
<div v-if="show" class="v-modal flex flex-center flex-middle fade-in">
    <div class="v-modal-dialog" :style="dialogStyle">
        <slot name="header">
            <div v-if="title" class="v-modal-header">{{ title }}</div>
        </slot>
        <slot>
            <div v-if="content" class="v-modal-body">{{ content }}</div>
        </slot>
        <slot name="footer">
            <div v-if="showCancelButton || showConfirmButton" class="flex flex-center v-modal-footer">
                <button v-if="showCancelButton" class="flex-item-1 btn btn-default is-round" @click="$emit('cancel', $event)">{{ cancelText }}</button>
                <button v-if="showConfirmButton" class="flex-item-1 btn btn-primary is-round" @click="$emit('confirm', $event)">{{ confirmText }}</button>
            </div>
        </slot>
    </div>
</div>
</template>

<script>
/* @props
 *
 * show (显示modal)
 *
 * title (标题)
 *
 * content (文本内容)
 *
 * cancelText (取消按钮文案)
 *
 * confirmText (确定按钮文案)
 *
 * showCancelButton (是否显示取消按钮)
 *
 * showConfirmButton (是否展示确定按钮)
 *
 * dialogStyle (对话框样式)
 */

/* @events
 *
 * cancel (点击取消按钮)
 *
 * confirm (点击确定按钮)
 */
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        showCancelButton: {
            type: Boolean,
            default: true
        },
        showConfirmButton: {
            type: Boolean,
            default: true
        },
        dialogStyle: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
      show(value) {
        if(value) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      }
    },
    methods: {
    }
};
</script>
