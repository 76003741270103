<template>
<div class="v-fetchmore">
    <slot name="icon" v-if="hasMore">
        <span class="icon-loading"></span>
    </slot>
    <span>{{ hasMore ? hasMoreText : text }}</span>
</div>
</template>

<script>

export default {
    props: {
        distance: { type: Number, default: 50 },
        text: { default: '没有更多了' },
        hasMoreText: { default: '' },
        hasMore: { type: Boolean, default: true },
        action: { type: Function, required: true },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            target: false
        };
    },
    mounted() {
        this.target = getScrollEventTarget(this.$el);
        $.dom.on(this.target, 'scroll', this.fetchmore, { passive: true });
    },
    beforeDestroy() {
        $.dom.off(this.target, 'scroll', this.fetchmore);
    },
    methods: {
        fetchmore() {
            if (this.loading || !this.hasMore) return;
            let target = this.target === window ? document.documentElement : this.target;
            let top = getScrollTop(this.target);
            let bottom = top + target.clientHeight;
            if (target.scrollHeight - bottom <= this.distance) {
                this.action();
            }
        }
    }
};

const getScrollEventTarget = (element) => {
    let currentNode = element;
    while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        let overflowY = $.dom.css(currentNode).overflowY;
        if (overflowY === 'scroll' || overflowY === 'auto') {
            return currentNode;
        }
        currentNode = currentNode.parentNode;
    }
    return window;
};
const getScrollTop = (element) => {
    if (element === window) {
        return Math.max(window.pageYOffset || 0, document.documentElement.scrollTop);
    }
    return element.scrollTop;
};

</script>
