<template>
  <div class="fold-wrapper">
    <i
      :class="[fold ? 'el-icon-caret-right' : 'el-icon-caret-bottom',isMobile ? 'show-icon-bottom' :'' ]"
      @click="toggleFold"
    ></i>
  </div>
</template>
<script>
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "toggle-content",
  props: {
    fold: {
      type: Number,
      default: 0,
    },
    blockId: {
      type: String,
      default: "",
    },
    foldChild: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      isMobile,
    };
  },
  methods: {
    toggleFold() {
      this.$eventBus.$emit("toggleFold", {
        fold: this.fold == 1 ? 0 : 1,
        blockId: this.blockId,
        foldChild: this.foldChild,
      });
    },
  },
};
</script>
<style>
.fold-wrapper{
  height: 100%;
  display: flex;
  justify-content: center;
}
.show-icon-bottom{
  display: inline-block !important;
}
</style>