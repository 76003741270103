import Vue from 'vue';
import * as ledap from 'ledap';
import vway from 'ether-vway';
window.ledap = ledap;

// 以下是ledap组件
const themeConfig = {
    // TODO: maxlength属性写在baseinput组件里面应该更合适一些，可以更加方便的适配
    'form-item': {
        template: `
        <component :is="tag" class="v-form-item" :class="{ 'is-required': model.isRequired(attr) }" :id="attr + 'Item'">
            <slot name="label" :model="model" :attr="attr">
                <label class="v-form-label">
                    <span class="v-form-label-text">{{model.getAttributeLabel(attr)}}</span>
                </label>
            </slot>
            <div class="v-form-item-content">
                <slot :model="model" :attr="attr" :validate="validate" :inputListeners="inputListeners">
                    <baseinput :model="model" :attr="attr" :inputListeners="inputListeners" v-bind="$attrs"></baseinput>
                </slot>
                <slot name="error" :model="model" :attr="attr" :showError="showError">
                    <div v-show="showError" class="v-form-item-error">{{showError}}</div>
                </slot>
            </div>
        </component>`
    },
    "baseinput": {
        template: `<div v-if="tag !== 'textarea'" class="v-input-wrapper">
            <input class="v-input" :name="attr" :value="model[attr]" :placeholder="model.getAttributeHint(attr)" v-on="inputListeners" v-bind="$attrs" :maxlength="cMaxlength">
        </div>
        <div v-else class="v-input-wrapper v-textarea-wrapper">
            <textarea class="v-input" :name="attr" :value="model[attr]" :placeholder="model.getAttributeHint(attr)" v-on="inputListeners" v-bind="$attrs" :maxlength="cMaxlength">
            </textarea>
            <div class="v-input-number">
                {{ model[attr] ? model[attr].length : 0 }}/{{ cMaxlength }}
            </div>
        </div>`,
    },
    'groupinput': {
        template: `<group :max="dictOption.max" :excludes="dictOption.excludes" :init-value="model[attr]" :multiple="dictOption.multiple" @change="groupChange" v-on="inputListeners" class="v-groupinput">
            <slot name="default" v-for="key in dictOption.order" :data-key="key" :value="dictOption.list[key]" :disabled="dictOption.excludes.indexOf(key) > -1 ? true : false">
                <tab :canClose="true" :disabled="dictOption.excludes.indexOf(key) > -1 ? true : false" :data-key="key" :key="key">{{dictOption.list[key]}}</tab>
            </slot>
        </group>`,
    },
    'radio': {
        template: `<component :is="tagName" class="v-radio" :class="{'is-checked': isOpen()}" @click="click">
            <slot name="input" :isOpen="isOpen" :disabled="disabled">
                <span class="v-radio-inner">
                    <i class="v-radio-icon"></i>
                    <input type="radio" class="v-radio-input" :checked="isOpen()" />
                </span>
            </slot>
            <span class="v-radio-text">
                <slot></slot>
            </span>
        </component>`,
    },
    'checkbox': {
        template: `<component :is="tagName" class="v-checkbox" :class="{'is-checked': isOpen()}" @click="click">
            <slot name="input" :isOpen="isOpen" :disabled="disabled">
                <span class="v-checkbox-inner">
                    <i class="v-checkbox-icon"></i>
                    <input type="checkbox" :checked="isOpen()" :disabled="disabled" class="v-checkbox-input" />
                </span>
            </slot>
            <span class="v-checkbox-text">
                <slot></slot>
            </span>
        </component>`,
    }
};

const webDpConfig = {
    primaryKey: 'id',
    timeWait: 0,
    httpRequest: vway.request
};

ledap.App.config({
    themeConfig,
    webDpConfig,
});
ledap.App.register(Object.keys(ledap.App.getTheme().components), Vue);