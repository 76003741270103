import CryptoJS from 'crypto-js';
import keyHash from './key_HASH.js';
import vway from 'ether-vway';

let _key = 'BDFHJLNPRTVXZ\\^`';
let iv = CryptoJS.enc.Utf8.parse(_key);

const objKeySort = oldObj => {
    let newKey = Object.keys(oldObj).sort();
    let newObj = {};
    newKey.forEach((val, i) => {
        newObj[newKey[i]] = oldObj[newKey[i]];
    });
    return newObj;
};

const getAuthKey = token => {
    token = token || '';
    let word = _key + '~' + token + '~' + Math.round(new Date().getTime() / 1000);
    let key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(_key));
    let encrypted = CryptoJS.AES.encrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
};

const getURIHashString = option => {
    let urlString = option.uri + '?',
        pKey, transArr = [];
    for (pKey in objKeySort(option.params)) {
        transArr.push(pKey + '=' + option.params[pKey]);
    }
    urlString += transArr.join('&');
    if (option.method.toUpperCase() == 'POST' && option.data) {
        urlString += '\n\n' + JSON.stringify(option.data);
    }
    return CryptoJS.HmacSHA1(urlString, keyHash).toString();
}

function decryptAES(encryptedData) {  
    let token = vway.ls.getGlobal('TOKEN')
    const keyHex = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(token));  
    const ivHex = CryptoJS.enc.Utf8.parse(token);  
    const decrypted = CryptoJS.AES.decrypt(encryptedData, keyHex, {  
        iv: ivHex,  
        mode: CryptoJS.mode.CBC,  
        padding: CryptoJS.pad.Pkcs7  
    });  
    return decrypted.toString(CryptoJS.enc.Utf8);  
}

export const encrypt = {
    getAuthKey,
    getURIHashString,
    decryptAES,
};