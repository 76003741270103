<template>
<span class="countdown-container">
    <slot
        v-for="i in keys"
        :name="i"
        :value="timeDiffMap[i].value"
        :unit="timeDiffMap[i].unit"
        :format="timeDiffMap[i].format"
        :pattern="timeDiffMap[i].pattern">
        <span class="time-value">{{ handleValue(timeDiffMap[i].value) }}</span><span v-if="timeDiffMap[i].unit" class="time-unit">{{ timeDiffMap[i].unit }}</span>
    </slot>
    <slot name="decimal" v-if="showDecimal">
        <span class="time-value time-decimal">{{ decimal }}</span>
    </slot>
</span>
</template>

<script>
export default {
    name: 'countdown',
    props: {
        ddl: {
            type: [String, Date],
            required: true,
        },
        pattern: {
            type: Array,
            default: () => ['d天', 'h小时', 'm分'],
        },
        showDecimal: {
            type: Boolean,
            default: false
        },
        omit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            timer: null,
            decimalTimer: null,
            timeDiffMap: {},
            decimal: ''
        };
    },
    computed: {
        keys() {
            const r = new RegExp("(" + Object.keys(this.timeDiffMap).join("|") + ")");
            return this.pattern.map(i => {
                return i.match(r)[0];
            }).filter(i => i);
        },
    },
    created() {
        this.countdown(this.ddl);
    },
    beforeDestroy() {
        this.destroy();
    },
    methods: {
        countdown(ddl) {
            if (typeof ddl == 'string') {
              ddl = new Date(ddl.replace(/-/g, '/'));
            }
            this.timer = $.util.countdown((milliseconds) => {
                this.timeDiffMap = $.util.formatTimeDiff(
                    milliseconds,
                    this.pattern,
                    {
                        omit: this.omit,
                        returnMap: true,
                    }
                );
            }, ddl);
            if (!this.showDecimal) return;
            let num = 9;
            this.decimalTimer = setInterval(() => {
                if (num < 0) num = 9;
                if (this.timer.isDestroyed) {
                    clearInterval(this.decimalTimer)
                    num = 0;
                }
                this.decimal = ('.' + num--);
            }, 100);
        },
        destroy() {
            if (this.timer && !this.timer.isDestroyed) this.timer.destroy();
            clearInterval(this.decimalTimer);
        },
        handleValue(num) {
            return num < 10 ? '0' + num : num;
        }
    },
    watch: {
        ddl(val) {
            this.destroy();
            this.countdown(val);
        }
    },
};
</script>
<style lang="less" scoped>
@import (reference) "~mobile-base.less";

.time-value {
    display: inline-block;
    width: 1.2em;
    text-align: center;
}
.time-decimal {
    margin-left: -.4em;
}
</style>
