const ADMISSION = 'admission'
const BUSINESS = 'business'
const INTERNAL = 'internal'
const ORIGIN = 'origin'
const REPLAY2023  = 'replay-2023'
const MANAGEMENT = 'management'
const MANAGEMENTFREE = 'management-free'
const FREE = 'free'
const REPLAYC = 'replayC'
const DY = 'dy'
const DYSPECIAL = 'dy-special'
const MANAGEMENTDY = 'management-dy'
const EQUITY = 'equity'
const ADMISSIONSPECIAL = 'admission-special'
const REPLAYB = 'replayB'
export default {
    ADMISSION,
    BUSINESS,
    INTERNAL,
    ORIGIN,
    MANAGEMENT,
    MANAGEMENTFREE,
    REPLAY2023,
    FREE,
    REPLAYC,
    DY,
    DYSPECIAL,
    MANAGEMENTDY,
    EQUITY,
    ADMISSIONSPECIAL,
    REPLAYB
}