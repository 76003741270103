var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.floatInfo.isShow
    ? _c("div", { staticClass: "float-window-box" }, [
        _vm.floatFlag
          ? _c(
              "div",
              {
                staticClass: "float-content",
                on: {
                  click: _vm.closeFloat,
                  touchmove: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "float-content-img",
                  attrs: { src: _vm.floatInfo.img.banner, alt: "" },
                  on: {
                    touchstart: _vm.longPress,
                    touchend: _vm.clearLongPress,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "float-content-hint" }, [
                  _vm._v(_vm._s(_vm.floatInfo.hint)),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isMobile
          ? _c(
              "div",
              {
                staticClass: "float-window",
                class: _vm.touchFlag
                  ? "float-window-right"
                  : "float-window-left",
                on: { click: _vm.openFloat },
              },
              [
                _c("img", {
                  staticClass: "float-window-bgimg",
                  attrs: { src: _vm.floatInfo.img.introduce, alt: "" },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "float-window-btnimg",
                  attrs: { src: _vm.floatInfo.img.button, alt: "" },
                }),
              ]
            )
          : _c(
              "div",
              {
                staticClass: "float-window float-window-pc",
                on: { click: _vm.openFloat },
              },
              [
                _c("img", {
                  staticClass: "float-window-bgimg",
                  attrs: { src: _vm.floatInfo.img.introduce, alt: "" },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "float-window-btnimg",
                  attrs: { src: _vm.floatInfo.img.button, alt: "" },
                }),
              ]
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }