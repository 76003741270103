<template>
    <div v-if="floatInfo.isShow" class="float-window-box">
        <div v-if="floatFlag" @click="closeFloat" @touchmove.prevent class="float-content">
            <img @touchstart="longPress" @touchend="clearLongPress" class="float-content-img"
                :src="floatInfo.img.banner" alt="">
            <div class="float-content-hint">{{ floatInfo.hint }}</div>
        </div>
        <div v-if="isMobile" @click="openFloat" class="float-window" :class="touchFlag ? 'float-window-right' : 'float-window-left'">
            <img class="float-window-bgimg" :src="floatInfo.img.introduce" alt="">
            <img class="float-window-btnimg" :src="floatInfo.img.button" alt="">
        </div>
        <div v-else @click="openFloat" class="float-window float-window-pc">
            <img class="float-window-bgimg" :src="floatInfo.img.introduce" alt="">
            <img class="float-window-btnimg" :src="floatInfo.img.button" alt="">
        </div>
    </div>
</template>
    
<script>
import * as bekit from 'bekit';
const { Ua } = bekit.helper;
export default {
    props: {
    },
    data() {
        return {
            touchFlag: false,
            floatFlag: false,
            isMobile: Ua.isMob(),
            timer: null,
            longTimer: null,
            scrollTop: 0,
            stopScroll: 0,
            touchMoveFlag: false,
            floatInfo: { img: {} }
        }
    },
    created() {
        $.request({
            url: '/carousel/image'
        }, data => {
            if (data.isShow) {
                $.stat.add('action', { name: 'show_float_' + this.$route.path.slice(1) });
            }
            this.floatInfo = data;
        });
        window.addEventListener("scroll", this.touchScroll, true);
        document.addEventListener('touchend', this.touchend, true);
        document.addEventListener('touchmove', this.touchmove, true);
    },
    beforeDestroy() {
        document.removeEventListener("touchend", this.touchend, true)
        document.removeEventListener("touchmove", this.touchmove, true)
        window.removeEventListener("scroll", this.touchScroll, true)
    },
    methods: {
        openFloat() {
            if (!this.floatInfo.img.banner) {
                $.request({
                    url: this.floatInfo.bannerCallback
                }, data => {
                    this.floatInfo.img.banner = data.image
                });
            }
            this.floatFlag = true
            $.stat.add('action', { name: 'open_float_' + this.$route.path.slice(1) });
        },
        closeFloat() {
            this.floatFlag = false
            $.stat.add('action', { name: 'close_float_' + this.$route.path.slice(1) });
        },
        longPress() {
            this.longTimer = setTimeout(() => {
                $.stat.add('action', { name: 'long_float_' + this.$route.path.slice(1) });
            }, 1000)
        },
        clearLongPress() {
            clearTimeout(this.longTimer);
        },
        touchmove(e) {
            this.touchFlag = true
            this.touchMoveFlag = false
        },
        touchend(e) {
            this.touchMoveFlag = true
            this.scrollEnd()
        },
        touchScroll() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.scrollEnd, 30);
            this.scrollT = document.documentElement.scrollTop || document.body.scrollTop;
        },
        scrollEnd() {
            this.stopscroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (this.stopscroll == this.scrollT && this.touchMoveFlag) {
                this.touchFlag = false
            }
        }
    }
};
</script>
<style lang="less">
@import (reference) "~mobile-base.less";

.float-window-box {
    .float-content {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-img {
            width: 500/@base;
        }
        &-hint {
            margin-top: 40/@base;
            font-size: 40/@base;
            color: #fff;
            white-space: pre-line;
            text-align: center;
        }
    }
    .float-window {
        position: fixed;
        bottom: 180/@base;
        right: 20/@base;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;
        width: 120/@base;

        .float-window-bgimg {
            width: 120/@base;
            // animation: move 3s ease-in-out infinite;
        }

        .float-window-btnimg {
            width: 100/@base;
            position: absolute;
            bottom: 15/@base;
            animation: scaleDraw 1s ease-in-out infinite;
        }
    }
    .float-window-pc {
        cursor: pointer;
        width: 160/@base;
        right: calc((100% - 8rem) / 2 + 30px);
        .float-window-bgimg {
            width: 160/@base;
        }
        .float-window-btnimg {
            width: 120/@base;
        }
    }
    .float-window-right {
        animation: toRight 0.5s ease-in-out forwards;
    }

    .float-window-left {
        animation: toLeft 0.5s ease-in-out forwards;
    }

    @keyframes toRight {
        0% {
            right: 20/@base;
            opacity: 1;
        }

        100% {
            right: -40/@base;
            opacity: 0.5;
        }
    }

    @keyframes toLeft {
        0% {
            right: -40/@base;
            opacity: 0.5;
        }

        100% {
            right: 20/@base;
            opacity: 1;
        }
    }

    @keyframes scaleDraw {
        00% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes move {

        0%,
        65% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        70% {
            -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
        }

        75% {
            -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
        }

        80% {
            -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
        }

        85% {
            -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
        }

        90% {
            -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
        }

        95% {
            -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
        }

        100% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }
}
</style>
