var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c(
        "div",
        {
          staticClass: "block docx-bullet-block",
          class: [
            _vm.fatherType == 34 ? "quote-container-render-unit" : "",
            `block-${_vm.children.blockId}`,
            _vm.children.blockStyles && _vm.children.blockStyles.textBackground
              ? "background"
              : "",
          ],
        },
        [
          _c(
            "div",
            { staticClass: "list-wrapper bullet-list" },
            [
              _c(
                "div",
                {
                  staticClass: "list",
                  class: _vm.getAlignFlex(
                    _vm.children.blockAttr.bullet.style.align
                  ),
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bullet bulletUnedit",
                      attrs: { contenteditable: "false" },
                    },
                    [
                      _c("div", { staticClass: "bullet-dot-style" }, [
                        _vm._v(_vm._s(_vm.getOrder())),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-content" }, [
                    _c(
                      "div",
                      { staticClass: "text-editor", class: _vm.pageStyle },
                      [
                        _c("v-spantext", {
                          attrs: {
                            elements: _vm.elements,
                            children: _vm.children,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("v-highlight", { attrs: { blockId: _vm.children.blockId } }),
              _vm._v(" "),
              _vm.children.childrens && _vm.children.childrens.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "list-children",
                      staticStyle: {
                        "padding-left": "18px",
                        "margin-left": "7px",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "render-unit-wrapper" },
                        [_vm._t("default", null, { olIndex: _vm.olIndex + 1 })],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.children.blockStyles && _vm.children.blockStyles.textBackground
            ? _c("v-blockbackground", {
                attrs: {
                  backgroundColor: _vm.children.blockStyles.textBackground,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }