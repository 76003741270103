var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source.type == -1
    ? _c(
        "div",
        {
          staticClass: "block docx-code-block",
          class: [
            `block-${_vm.children.blockId}`,
            _vm.children.blockStyles && _vm.children.blockStyles.textBackground
              ? "background"
              : "",
          ],
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "docx-code-block-container" }, [
            _c("div", { staticClass: "editor-kit-code-block code-block" }, [
              _c("div", { staticStyle: { "user-select": "none" } }, [
                _c(
                  "div",
                  {
                    staticClass: "code-block-header",
                    style: _vm.isMobile && { opacity: 1 },
                  },
                  [
                    _c("div", { staticStyle: { color: "#646a73" } }, [
                      _vm._v(
                        "\n            " + _vm._s(_vm.language) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "remain-space" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false },
                            on: { click: _vm.copyContent },
                          },
                          [_vm._v("复制")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "code-block-content",
                  class: true ? "code-wrap-content" : "",
                  style: _vm.isMobile && { marginTop: "30px" },
                },
                [
                  !_vm.isMobile
                    ? _c(
                        "div",
                        { staticClass: "search-code-class" },
                        [
                          _c("v-spantext", {
                            attrs: {
                              elements: _vm.elements,
                              children: _vm.children,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "zone-container code-block-zone-container text-editor-code",
                    },
                    _vm._l(_vm.codeElements, function (code, index) {
                      return _c("div", { staticClass: "ace-line" }, [
                        _c(
                          "div",
                          {
                            staticClass: "code-line-wrapper",
                            attrs: { "data-line-num": index + 1 },
                          },
                          [
                            _vm._l(code.elements, function (item, index) {
                              return [
                                item.text_run
                                  ? _c("span", {
                                      key: index,
                                      class: [
                                        _vm.getEleStyle(
                                          item.text_run.text_element_style
                                        ),
                                        _vm.isUrlRegExp(item.text_run.content)
                                          ? " link-text-color "
                                          : "",
                                      ],
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.highlightedCode(
                                            item.text_run.content,
                                            _vm.language
                                          )
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openUrl(
                                            item.text_run.text_element_style
                                              .link,
                                            item.text_run.content,
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-string": "true",
                                  "data-enter": "true",
                                  "data-leaf": "true",
                                },
                              },
                              [_vm._v("​")]
                            ),
                          ],
                          2
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-highlight", { attrs: { blockId: _vm.children.blockId } }),
          _vm._v(" "),
          _vm.children.blockStyles && _vm.children.blockStyles.textBackground
            ? _c("v-blockbackground", {
                attrs: {
                  backgroundColor: _vm.children.blockStyles.textBackground,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _c(_vm.blockComponent[_vm.source.type], {
        tag: "component",
        attrs: { children: _vm.source.children },
      })
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }