var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fold-wrapper" }, [
    _c("i", {
      class: [
        _vm.fold ? "el-icon-caret-right" : "el-icon-caret-bottom",
        _vm.isMobile ? "show-icon-bottom" : "",
      ],
      on: { click: _vm.toggleFold },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }