<template>  
    <div class="ace-line">
        <template v-for="(item, index) in elements">
        <span
            v-if="item.text_run"
            :class="[
            getEleStyle(item.text_run.text_element_style),
            isUrlRegExp(item.text_run.content) ? ' link-text-color ' : '',
            ]"
            :key="index"
            @click="
            openUrl(
                item.text_run.text_element_style.link,
                item.text_run.content,
                $event
            )
            "
            >{{ item.text_run.content }}</span><span
            v-if="item.mention_doc"
            :key="index"
            :class="[
            getEleStyle(item.mention_doc.text_element_style),
            item.mention_doc.obj_type == 22 || item.mention_doc.obj_type == 16 ? ' link-text-color ' : '',//22 docx 16 wiki
            ]"
            @click="openMentionUrl(item.mention_doc.url, item.mention_doc.obj_type)"
        >{{ item.mention_doc.title }}</span><span
            v-if="item.inline_block"
            :key="index"
            :class="[
            getEleStyle(item.inline_block.text_element_style),
            ]"
        >{{ getLineBlockText(item.inline_block.block_id) }}</span>
        </template>
        <span data-string="true" data-enter="true" data-leaf="true">&ZeroWidthSpace;</span>
    </div>
</template>
<script>
import { pageStyles, getEleStyle } from "@/views/desktop/fsdoc/utils/style";
import { isUrlRegExp, openUrl } from "@/views/desktop/fsdoc/utils/utils";
export default {
    name:'span-text',
    props:{
        children: {
            type: Object,
            default: null,
        },
        elements:{
            type:Array,
            default(){
                return []
            }
        }
    },
    data() {
        return {
        getEleStyle,
        isUrlRegExp,
        openUrl,
        };
    },
    methods:{
         getLineBlockText(block_id){
            let childrens = this.children.childrens
            let child = childrens.filter((c) => {
                return c.blockId === block_id
            })[0]
            if(child){
                if(child.blockAttr.file){
                    return child.blockAttr.file.name
                }
                return ''
            }
            return ''
        },
        openTargetUrl(token){
            let location_url_obj = new URL(location.href) 
            let location_url_pathname = location_url_obj.pathname
            let location_url_pathname_arr = location_url_pathname.split('/')
            location_url_pathname_arr[3] = token
            location_url_pathname = location_url_pathname_arr.join('/')
            let current_url = location_url_obj.origin + location_url_pathname + location_url_obj.search
            window.open(current_url, "_blank");
        },
        openMentionUrl(url, obj_type){
            if(obj_type == 22){
                let url_obj = new URL(url)
                let pathname = url_obj.pathname;
                let token = pathname.split('/')[2]
                if(token){
                    this.openTargetUrl(token)
                }
            }
            if(obj_type == 16){
                $.request(
                    {
                        url: "/feishu/wiki-to-docx",
                        params: {
                            url: url
                        }
                    }, (res) => {
                        let token = res.token;
                        if(token){
                            this.openTargetUrl(token)
                        }
                    }
                )
            }
        }
    }
}
</script>