import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
export default new Vuex.Store({
  //数据，相当于data
  state: {
    showSearch:false,
    searchKey:'',
    searchResultCount:0,
    searchResultIndex:0,
    searchPageBlockIds:[],
    perviewImageUrl:'',
    // fs-doc
    fsDocTitle:''
  },
  getters: {
    
  },
  //里面定义方法，操作state方发
  mutations: {
    setSearchKey(state, key){
      state.searchKey = key;
    },
    setShowSearch(state, status){
      state.showSearch = status;
    },
    setCount(state, count){
      state.searchResultCount = count;
    },
    setIndex(state, index){
      state.searchResultIndex = index;
    },
    setSearchBlockIds(state, blockId){
      state.searchPageBlockIds.push(blockId);
    },
    clearSearchBlockIds(state){
      state.searchPageBlockIds = []
    },
    setPerviewImageUrl(state, url){
      state.perviewImageUrl = url;
    },
    setFsDocTitle(state, title){
      state.fsDocTitle = title;
    },
  },
  // 操作异步操作mutation
  actions: {
    
  },
  modules: {
    
  },
})