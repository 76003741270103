var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fragment", [
    _vm.children
      ? _c(
          "div",
          {
            staticClass: "block docx-grid_column-block",
            style: _vm.deviceStyle,
          },
          [
            _c("div", { staticClass: "grid-column-block" }, [
              _c(
                "div",
                { staticClass: "render-unit-wrapper" },
                [_vm._t("default")],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isMobile
      ? _c(
          "div",
          {
            staticClass: "bear-virtual-renderUnit-placeholder",
            staticStyle: {
              height: "0px",
              "margin-top": "0px",
              "margin-bottom": "0px",
              display: "none",
            },
          },
          [_c("span")]
        )
      : _c("div", { staticClass: "column-gap j-column-gap" }, [
          _c("div", {
            staticClass: "column-gap-inner",
            attrs: { contenteditable: "false" },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }