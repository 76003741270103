var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.searchKey && _vm.highlight.length > 0
    ? _c(
        "div",
        { staticClass: "fs-highlight" },
        _vm._l(_vm.highlight, function (item) {
          return _c("span", {
            staticClass: "doc-tag",
            class:
              _vm.blockId == _vm.currentBlockId && _vm.currentSign == item.index
                ? "tag-active"
                : "",
            style: {
              left: item.left + "px",
              top: item.top + "px",
              width: item.width + "px",
              height: item.height + "px",
            },
            attrs: {
              id: `${_vm.blockId}-${item.index}`,
              "data-index": item.index,
            },
          })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }