var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-fetchmore" },
    [
      _vm.hasMore
        ? _vm._t("icon", function () {
            return [_c("span", { staticClass: "icon-loading" })]
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.hasMore ? _vm.hasMoreText : _vm.text))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }