export default function (el, binding, vnode) {
  const value = binding.value;
  const oldValue = binding.oldValue;
  if (value == oldValue) return;
  el.innerHTML = _parse(value);
};

function _parse(str) {
  const el = document.createElement('div');
  el.innerText = str;
  el.innerHTML.replace(/\s/g, '&nbsp;');
  // 将里面的链接替换成可点击的链接
  let linkReg = /(http|https):\/\/([\w\d_-]+(\.|))+(\/[\w\d_-]+)*(\w|\d|\?|_|&|=|#|\.|)*/ig;
  return el.innerHTML.replace(linkReg, match => {
    return '<a target="_blank" rel="noopener noreferrer" href=' + match + '>' + match + '</a>';
  });
}