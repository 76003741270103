var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.children
    ? _c(
        "div",
        {
          staticClass: "switch-warp",
          class: _vm.mode == 1 ? "fixed-height" : "flex-height",
        },
        [
          _c(_vm.blockComponent[_vm.children.type], {
            tag: "component",
            attrs: { children: _vm.children },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "switch-block",
              style: {
                "overscroll-behavior-x": _vm.isIOS ? "none" : "auto",
              },
            },
            [
              _vm.children.childrens.length > 0
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.children.childrens, function (child, index) {
                        return [
                          _c("page-main", {
                            key: child.blockId,
                            attrs: {
                              level: -1,
                              olIndex: 0,
                              source: child,
                              index: index,
                              sources: _vm.children.childrens,
                              fatherType: _vm.children.type,
                              fatherBlockId: _vm.children.blockId,
                              prevChild:
                                index - 1 >= 0
                                  ? _vm.children.childrens[index - 1]
                                  : null,
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.mode == 1
            ? [
                _vm.switchType == 0 || _vm.switchType == 1
                  ? _c("div", { staticClass: "operate-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "yt-button yt-button--default yt-button--small",
                          attrs: { type: "button" },
                          on: { click: _vm.prevPage },
                        },
                        [_c("span", [_vm._v("上一页")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "yt-button yt-button--default yt-button--small",
                          attrs: { type: "button" },
                          on: { click: _vm.nextPage },
                        },
                        [_c("span", [_vm._v("下一页")])]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                (_vm.switchType == 0 || _vm.switchType == 1) && _vm.isFixed
                  ? _c(
                      "div",
                      {
                        staticClass: "operate-footer fixed-footer",
                        style: {
                          "margin-left": _vm.hmarginLeft + "px",
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "yt-button yt-button--default yt-button--small",
                            attrs: { type: "button" },
                            on: { click: _vm.prevPage },
                          },
                          [_c("span", [_vm._v("上一页")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "yt-button yt-button--default yt-button--small",
                            attrs: { type: "button" },
                            on: { click: _vm.nextPage },
                          },
                          [_c("span", [_vm._v("下一页")])]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.switchType == 2 || _vm.switchType == 3
                  ? _c("div", { staticClass: "operate-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "yt-button yt-button--default yt-button--small",
                          attrs: { type: "button" },
                          on: { click: _vm.prevPage },
                        },
                        [_c("span", [_vm._v("上一页")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "yt-button yt-button--default yt-button--small",
                          class: _vm.isPass ? "dislike" : "",
                          attrs: { type: "button" },
                          on: { click: _vm.disLikePage },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("van-icon", {
                                attrs: {
                                  name: _vm.isPass ? "share" : "share-o",
                                },
                              }),
                              _vm._v(" 过"),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "yt-button yt-button--default yt-button--small",
                          class: _vm.isLike ? "like" : "",
                          attrs: { type: "button" },
                          on: { click: _vm.likePage },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("van-icon", {
                                attrs: { name: _vm.isLike ? "like" : "like-o" },
                              }),
                              _vm._v(" 赞"),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLike || _vm.isPass,
                              expression: "isLike || isPass",
                            },
                          ],
                          staticClass:
                            "yt-button yt-button--default yt-button--small",
                          attrs: { type: "button" },
                          on: { click: _vm.nextPage },
                        },
                        [_c("span", [_vm._v("下一页")])]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                (_vm.switchType == 2 || _vm.switchType == 3) && _vm.isFixed
                  ? _c(
                      "div",
                      {
                        staticClass: "operate-footer fixed-footer",
                        style: {
                          "margin-left": _vm.hmarginLeft + "px",
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "yt-button yt-button--default yt-button--small",
                            attrs: { type: "button" },
                            on: { click: _vm.prevPage },
                          },
                          [_c("span", [_vm._v("上一页")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "yt-button yt-button--default yt-button--small",
                            class: _vm.isPass ? "dislike" : "",
                            attrs: { type: "button" },
                            on: { click: _vm.disLikePage },
                          },
                          [
                            _c(
                              "span",
                              [
                                _c("van-icon", {
                                  attrs: {
                                    name: _vm.isPass ? "share" : "share-o",
                                  },
                                }),
                                _vm._v(" 过"),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "yt-button yt-button--default yt-button--small",
                            class: _vm.isLike ? "like" : "",
                            attrs: { type: "button" },
                            on: { click: _vm.likePage },
                          },
                          [
                            _c(
                              "span",
                              [
                                _c("van-icon", {
                                  attrs: {
                                    name: _vm.isLike ? "like" : "like-o",
                                  },
                                }),
                                _vm._v(" 赞"),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isLike || _vm.isPass,
                                expression: "isLike || isPass",
                              },
                            ],
                            staticClass:
                              "yt-button yt-button--default yt-button--small",
                            attrs: { type: "button" },
                            on: { click: _vm.nextPage },
                          },
                          [_c("span", [_vm._v("下一页")])]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm.mode == 2
            ? [
                _c("div", { staticClass: "fixed-random" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "yt-button yt-button--default yt-button--small el-button--primary",
                      attrs: { type: "button" },
                      on: { click: _vm.randomPage },
                    },
                    [_c("span", [_vm._v("随机换一个H3")])]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }