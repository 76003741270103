<template>
  <div
    v-if="children"
    class="switch-warp"
    :class="mode == 1 ? 'fixed-height' : 'flex-height'"
  >
    <component
      :is="blockComponent[children.type]"
      :children="children"
    ></component>
    <div
      class="switch-block"
      :style="{
        'overscroll-behavior-x': isIOS ? 'none' : 'auto',
      }"
    >
      <div v-if="children.childrens.length > 0">
        <template v-for="(child, index) in children.childrens">
          <page-main
            :level="-1"
            :olIndex="0"
            :source="child"
            :key="child.blockId"
            :index="index"
            :sources="children.childrens"
            :fatherType="children.type"
            :fatherBlockId="children.blockId"
            :prevChild="index - 1 >= 0 ? children.childrens[index - 1] : null"
          ></page-main>
        </template>
      </div>
    </div>
    <template v-if="mode == 1">
      <div class="operate-footer" v-if="switchType == 0 || switchType == 1">
        <button
          @click="prevPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
        >
          <span>上一页</span>
        </button>
        <button
          @click="nextPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
        >
          <span>下一页</span>
        </button>
      </div>
      <div
        class="operate-footer fixed-footer"
        :style="{
          'margin-left': hmarginLeft + 'px',
        }"
        v-if="(switchType == 0 || switchType == 1) && isFixed"
      >
        <button
          @click="prevPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
        >
          <span>上一页</span>
        </button>
        <button
          @click="nextPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
        >
          <span>下一页</span>
        </button>
      </div>
      <div class="operate-footer" v-if="switchType == 2 || switchType == 3">
        <button
          @click="prevPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
        >
          <span>上一页</span>
        </button>
        <button
          @click="disLikePage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
          :class="isPass ? 'dislike' : ''"
        >
          <span><van-icon :name="isPass ? 'share' : 'share-o'" />&nbsp;过</span>
        </button>
        <button
          @click="likePage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
          :class="isLike ? 'like' : ''"
        >
          <span><van-icon :name="isLike ? 'like' : 'like-o'" />&nbsp;赞</span>
        </button>
        <button
          @click="nextPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
          v-show="isLike || isPass"
        >
          <span>下一页</span>
        </button>
      </div>
      <div
        class="operate-footer fixed-footer"
        :style="{
          'margin-left': hmarginLeft + 'px',
        }"
        v-if="(switchType == 2 || switchType == 3) && isFixed"
      >
        <button
          @click="prevPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
        >
          <span>上一页</span>
        </button>
        <button
          @click="disLikePage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
          :class="isPass ? 'dislike' : ''"
        >
          <span><van-icon :name="isPass ? 'share' : 'share-o'" />&nbsp;过</span>
        </button>
        <button
          @click="likePage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
          :class="isLike ? 'like' : ''"
        >
          <span><van-icon :name="isLike ? 'like' : 'like-o'" />&nbsp;赞</span>
        </button>
        <button
          @click="nextPage"
          type="button"
          class="yt-button yt-button--default yt-button--small"
          v-show="isLike || isPass"
        >
          <span>下一页</span>
        </button>
      </div>
    </template>
    <template v-else-if="mode == 2">
      <div class="fixed-random">
        <button
          @click="randomPage"
          type="button"
          class="yt-button yt-button--default yt-button--small el-button--primary"
        >
          <span>随机换一个H3</span>
        </button>
      </div>
    </template>
  </div>
</template>
<script >
import pageMain from "./page-main.vue";
import { blockComponent } from "../utils/utils";
import { Icon } from "vant";
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "switch-block",
  props: {
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    fatherIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    pageMain,
    "van-icon": Icon,
  },
  inject: ["blockActions", "marginLeft", "targetBlockId"],
  data() {
    const isIOS = Ua.isIOS();
    return {
      blockComponent: blockComponent,
      switchType: this.$route.query.switchType,
      // targetBlockId: this.$route.query.blockId,
      tempAction: [],
      isBtnShow: false,
      isFixed: false,
      isIOS,
      mode: this.$route.query.mode || "0",
      index: 0,
    };
  },
  computed: {
    hmarginLeft() {
      return this.marginLeft();
    },
    children() {
      let children = this.source.childrens[this.index];
      return children;
    },
    hblockActions() {
      return this.blockActions().concat(...this.tempAction);
    },
    isPass() {
      let curBlockId = this.children.blockId;
      return this.hblockActions.some((item) => {
        return item.blockId == curBlockId && item.type == "pass";
      });
    },
    isLike() {
      let curBlockId = this.children.blockId;
      return this.hblockActions.some((item) => {
        return item.blockId == curBlockId && item.type == "zan";
      });
    },
  },
  mounted() {
    if (this.mode == 1) {
      this.$nextTick(() => {
        this.warpListener();
        this.btnListener();
      });
    }
  },
  methods: {
    warpListener() {
      const observer = new IntersectionObserver(
        (entries) => {
          let entrie = entries[0];
          let intersectionRatio = entrie.intersectionRatio;
          if (intersectionRatio >= 0.3 && !this.isBtnShow) {
            this.isFixed = true;
          } else {
            this.isFixed = false;
          }
        },
        { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 0.95, 1] }
      );
      const box = document.querySelector(`.item-${this.targetBlockId()}`);
      observer.observe(box);
    },
    btnListener() {
      const observer = new IntersectionObserver(
        (entries) => {
          let entrie = entries[0];
          let intersectionRatio = entrie.intersectionRatio;
          if (intersectionRatio == 0) {
            this.isBtnShow = false;
          }
          if (intersectionRatio == 1) {
            this.isBtnShow = true;
          }
        },
        { threshold: [0, 1] }
      );
      const box = document.querySelector(`.switch-warp .operate-footer`);
      observer.observe(box);
    },
    prevPage() {
      if (this.index == 0) {
        this.index = this.source.childrens.length - 1;
      } else {
        this.index = this.index - 1;
      }
      this.scrollTop();
    },
    nextPage() {
      if (this.index == this.source.childrens.length - 1) {
        this.index = 0;
      } else {
        this.index = this.index + 1;
      }
      this.scrollTop();
    },
    setPageIndex(index) {
      this.index = index;
      this.scrollTop();
    },
    scrollTop() {
      this.$nextTick(() => {
        let switchBlock = document.querySelector(".switch-block");
        switchBlock.scrollTo(0, 0);
      });
    },
    async likePage() {
      let params = {
        docId: this.$route.params.id,
        blockId: this.children.blockId,
        blockTitle: this.getLabelText(
          this.children.blockAttr.heading3.elements
        ),
        type: "zan",
      };
      let res = await this.blockLog(params);
      this.handlerRes(res, params);
    },
    async disLikePage() {
      let params = {
        docId: this.$route.params.id,
        blockId: this.children.blockId,
        blockTitle: this.getLabelText(
          this.children.blockAttr.heading3.elements
        ),
        type: "pass",
      };
      let res = await this.blockLog(params);
      this.handlerRes(res, params);
    },
    handlerRes(res, params) {
      if (res) {
        this.tempAction.push(params);
        this.$emit("push", params);
        this.nextPage();
      }
    },
    getLabelText(elements) {
      let str = ``;
      elements.forEach((item) => {
        str += item.text_run ? item.text_run.content : '';
      });
      return str;
    },
    async blockLog(params) {
      let res = await $.request({
        url: "/feishu/block-log",
        data: params,
        method: "POST",
        loading: { show: false },
      });
      return res;
    },
    randomPage() {
      if (this.index == this.source.childrens.length - 1) {
        this.index = 0;
      } else {
        this.index = this.index + 1;
      }
      this.$eventBus.$emit("changeEvent", { index: this.fatherIndex });
    },
  },
};
</script>
<style scoped>
.switch-warp {
  display: flex;
  flex-direction: column;
}
.fixed-height {
  height: calc(100vh - 150px);
}
.flex-height {
}
.switch-block {
  overflow: auto;
  flex: 1;
}
.operate-footer {
  position: absolute;
  bottom: -50px;
  left: 0;
  margin-left: 2px;
  z-index: 99;
}
.operate-footer .dislike {
  /* color: #909399;
    border-color: #d3d4d6; */
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
}
.operate-footer .like {
  color: #f56c6c;
  border-color: #f56c6c;
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  margin: 0 26px;
  padding: 5px 0 10px 20px;
  width: 100%;
}
button:hover {
  cursor: pointer;
}
.yt-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.yt-button--small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.yt-button + .yt-button {
  margin-left: 10px;
}
.el-button--primary {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
.fixed-random {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 20px;
}
</style>