<template>
  <div
    v-if="source.type == -1"
    class="block docx-code-block"
    :class="[
      `block-${children.blockId}`,
      children.blockStyles && children.blockStyles.textBackground
        ? 'background'
        : '',
    ]"
  >
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
    <div class="docx-code-block-container">
      <div class="editor-kit-code-block code-block">
        <div style="user-select: none;">
          <div class="code-block-header" :style="isMobile && {opacity: 1} ">
            <div style="color:#646a73">
              {{language}}
            </div>
            <div class="remain-space"></div>
            <div>
              <el-link :underline="false" @click="copyContent">复制</el-link>
            </div>
          </div>
        </div>
        <div class="code-block-content" 
            :class="true ? 'code-wrap-content':''"
            :style="isMobile && {marginTop:'30px'}">
          <div class="search-code-class" v-if="!isMobile">
                <v-spantext :elements="elements" :children="children"></v-spantext>
             </div>
          <div
            class="zone-container code-block-zone-container text-editor-code"
          >   
             <div class="ace-line"  v-for="(code, index) in codeElements">
              <div class="code-line-wrapper" :data-line-num="index + 1">
              <template v-for="(item, index) in code.elements">
              <span
                  v-if="item.text_run"
                  :class="[
                  getEleStyle(item.text_run.text_element_style),
                  isUrlRegExp(item.text_run.content) ? ' link-text-color ' : '',
                  ]"
                  :key="index"
                  @click="
                  openUrl(
                      item.text_run.text_element_style.link,
                      item.text_run.content,
                      $event
                  )
                  "
                  v-html="highlightedCode(item.text_run.content, language)"
                  ></span>
                </template>
              <span data-string="true" data-enter="true" data-leaf="true">&ZeroWidthSpace;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-highlight :blockId="children.blockId"></v-highlight>
    <v-blockbackground
      v-if="children.blockStyles && children.blockStyles.textBackground"
      :backgroundColor="children.blockStyles.textBackground"
    ></v-blockbackground>
  </div>
  <component
    v-else
    :is="blockComponent[source.type]"
    :children="source.children"
  ></component>
</template>
<script>
// Using ES6 import syntax
// 代码高亮的js
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import { blockComponent } from "../utils/utils";
import { pageStyles, getEleStyle } from "@/views/desktop/fsdoc/utils/style";
import { isUrlRegExp, openUrl, codeLanguage } from "@/views/desktop/fsdoc/utils/utils";
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "code-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  data() {
    const isMobile = Ua.isMob();
    return {
      isMobile,
      blockComponent,
      getEleStyle,
      isUrlRegExp,
      openUrl,
      codeLanguage
    };
  },
  computed: {
    elements() {
      return this.children.blockAttr.code.elements || [];
    },
    language(){
      let language = this.children.blockAttr.code.style.language || 1
      return this.codeLanguage[language]
    },
    codeElements(){
      let elements = []
      let nodes = []
      let tag = '\n'
      for(let i = 0; i < this.elements.length; i++){
        let element = this.elements[i]
        let elementCopy = JSON.parse(JSON.stringify(element))
        let content = element.text_run.content;
        let isBreak = content.includes(tag)
        let isBreakEnd = content.endsWith(tag)
        if(isBreak){
          let contentArr = content.split(tag)
          if(nodes.length > 0){
            elementCopy.text_run.content = contentArr.shift()
            nodes.push(elementCopy)
            elements.push({elements:nodes})
            nodes = []
          }
          let surplus =  contentArr.slice(0, -1) 
          surplus.map((content)=>{
            let elementCopy = JSON.parse(JSON.stringify(element))
            elementCopy.text_run.content = content
            elements.push({elements: [elementCopy]})
          })
          if(!isBreakEnd){
              let elementCopy = JSON.parse(JSON.stringify(element))
              elementCopy.text_run.content = surplus[surplus.length-1]
            nodes.push(elementCopy)
          }
        }else{
          if(i == this.elements.length - 1){
            elements.push({elements: [elementCopy]})
          }else{
            nodes.push(elementCopy)
          }
        }
      }
      return elements
    },
    source() {
      let str = "";
      for (let i = 0; i < this.elements.length; i++) {
        str += this.elements[i].text_run.content;
      }
      let [type, url, attr] = str.split("\n");
      if (attr) {
        var [width, height, align] = attr.split(",");
      }
      return {
        type: this.getType(type),
        children: {
          blockAttr: {
            proxy: 1,
            image: {
              height: height,
              width: width,
              align: align,
              url: url,
            },
          },
        },
      };
    },
  },
  methods: {
    getType(type) {
      if (type === "toImage") {
        return 27;
      }
      return -1;
    },
    highlightedCode(html,language){
      const highlightedCode = hljs.highlight(
        html,
        { language: language}
      ).value
      return highlightedCode
    },
    copyContent(){
      let str = "";
      for (let i = 0; i < this.elements.length; i++) {
        str += this.elements[i].text_run.content;
      }
      $.util.copyContent(str, () =>{
          $.ui.toast('复制成功');
      }, () => {
          $.ui.toast('复制失败！');
      });
    }
  },
};
</script>
<style >
.docx-code-block {
  transition: none !important;
}

.editor-kit-code-block {
    transition: none !important;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #2b2f36;
    color: var(#2b2f36, #2b2f36);
    background-color: #f5f6f7;
    border: 1px solid #dee0e3;
    border-radius: 4px;
    padding-bottom: 2px;
}
.docx-code-block .editor-kit-code-block {
    border-radius: 8px;
}
.editor-kit-code-block .code-block-content {
  margin: 24px 20px 0 0;
  font-family: monospace;
  display: flex;
  border-radius: 0 0 4px 4px;
  overflow-y: auto;
  overflow-x: visible;
  min-height: 53px;
  line-height: 22px !important;
  position: relative;
}
.editor-kit-code-block .code-block-header {
    min-width: 92px;
    height: 24px;
    font-size: 14px;
    border-radius: 4px 4px 0 0;
    padding: 4px 8px 0;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    opacity: 0;
    pointer-events: none;
}
.editor-kit-code-block .code-block-header .remain-space {
    height: 0;
    min-width: 1px;
    border: none;
    flex: 1;
}
.editor-kit-code-block:hover .code-block-header{
    opacity: 1;
    pointer-events: auto;
}
.editor-kit-code-block .code-block-content .code-block-zone-container {
  margin-left: 57px;
  flex: 1;
  padding-bottom: 24px;
  overflow: auto;
  outline: none;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.editor-kit-code-block .code-block-content .code-block-zone-container, .editor-kit-code-block .code-block-content .text-editor-code {
    text-align: left !important;
    white-space: pre !important;
    word-wrap: normal;
    word-break: keep-all;
    cursor: text;
    line-height: 22px !important;
    position: static;
}
.search-code-class{
  position: absolute;
  top: 0;
  opacity: 0;
  /* display: none; */
  left: 57px;
  z-index: -1;
}
.search-code-class span{
  white-space: pre;
}
.editor-kit-code-block .code-wrap-content  span {
    white-space: break-spaces !important;
}
.block .editor-kit-container, .block .text-editor-code {
    font-size: inherit;
    border-top-width: 0;
}
.editor-kit-container .ace-line {
    margin: 0 !important;
}
.editor-kit-container .text-editor-code *, .text-editor-code.editor-kit-container * {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.editor-kit-code-block .code-wrap-content .code-block-zone-container, .editor-kit-code-block .code-wrap-content .text-editor-code {
    white-space: pre-wrap !important;
    word-break: break-word;
}
.docx-code-block .code-line-wrapper {
    caret-color: #2b2f36;
}
.code-line-wrapper span{
  white-space: inherit;
}
.code-line-wrapper:before {
    content: attr(data-line-num);
    position: absolute;
    left: 0;
    text-align: right;
    width: 46px;
    color: #646a73;
    word-break: break-word;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    cursor: default;
    white-space: nowrap;
}
</style>