/**
 * @desc 对微信JS-SDK的封装
 */
let wx = require('weixin-js-sdk');
import * as bekit from 'bekit';
const Ua = bekit.helper.Ua;

class WxSDK {
    constructor(option) {
        if (!Ua.isWx()) return;
        this._option = {
            checkJSApi: option.checkJSApi || false,
            debug: option.debug || false,
            title: option.title || '一堂-创业课',
            desc: option.desc || '以太荣誉出品，一个认真做教研的创业培训',
            link: option.link || location.origin + '/index',
            imgUrl: option.imgUrl || 'https://cdn.yitang.top/upload/ether-public/yitang/16245289717c53c448030ece331a6c1467814ee57affe2cc10.jpeg',
            ready: option.ready || function() {},
            success: option.success || function() {}
        };
        this.checkJsApiResult = null;
        this.init();
    }
    // [init 程序初始化]
    init() {
        let _this = this;
        WxSDK.pri.getSdkApi().then(function(data) {
            return _this.initConf(data);
        }).then(function() {
            return _this.checkJsApi();
        }).then(function(res) {
            _this.setShare(res);
        }).catch($.apiCatch);
    }
    // [initConf 微信接口初始化]
    initConf(data = {}) {
        let _this = this;
        return new Promise(function(resolve, reject) {
            /**
             * 在vue-router + ios下由一定概率出现这种情况：config没问题、一切正常但是分享设置不生效
             * 从网上找到这个解决方案 回头再搞
             */
            // setTimeout(() => {
            wx.config({
                debug: _this._option.debug,
                appId: data.appId,
                timestamp: data.timestamp,
                nonceStr: data.nonceStr,
                signature: data.signature,
                jsApiList: WxSDK.pri.jsApiList
            });
            wx.ready(function(res) {
                _this._option.ready();
                resolve();
            });
            wx.error(function(err) {
                // alert('config error' + JSON.stringify(err));
            });
            // }, 500);
        });
    }
    // [checkJsApi 检测微信API时候运行正常]
    checkJsApi() {
        return new Promise(function(resolve, reject) {
            wx.checkJsApi({
                jsApiList: WxSDK.pri.jsApiList,
                success: function(res) {
                    resolve(res);
                }
            });
        })
    }
    // 设置分享内容
    setShare(res) {
        this.checkJsApiResult = res;
        this.updateAppMessageShareData(this._option);
        this.updateTimelineShareData(this._option);
    }
    // 分享到好友
    updateAppMessageShareData(option) {
        let _this = this;
        // wx.onMenuShareAppMessage({
        //     title: option.title,
        //     desc: option.desc,
        //     link: option.link,
        //     imgUrl: option.imgUrl,
        //     success: function(res) {
        //         typeof option.success === 'function' && option.success(res);
        //         _this.success(res);
        //     }
        // });
        // 新版api无法拿到分享回调，暂时用旧的
        wx.updateAppMessageShareData({
            title: option.title,
            desc: option.desc,
            link: option.link,
            imgUrl: option.imgUrl,
            success: function(res) {
                // this.success(res);
                typeof option.success === 'function' && option.success(res);
            }
        });
    }
    // 分享到朋友圈
    updateTimelineShareData(option) {
        let _this = this;
        // wx.onMenuShareTimeline({
        //     title: option.title,
        //     link: option.link,
        //     imgUrl: option.imgUrl,
        //     success: function(res) {
        //         typeof option.success === 'function' && option.success(res);
        //         _this.success(res);
        //     }
        // });
        // 新版api无法拿到分享回调，暂时用旧的
        wx.updateTimelineShareData({
            title: option.title,
            link: option.link,
            imgUrl: option.imgUrl,
            success: function(res) {
                // this.success(res);
                typeof option.success === 'function' && option.success(res);
            }
        });
    }
    success(res) {
        // alert('res' + JSON.stringify(res));
    }
    fail(err) {
        // alert('err' + JSON.stringify(err));
    }
}

// [pri 私有方法，不可读]
WxSDK.pri = {
    /**
     * [jsApiList]
     * @type {Array}
     */
    jsApiList: [
        'checkJSApi',
        // 'onMenuShareAppMessage',
        // 'onMenuShareTimeline',
        'updateTimelineShareData',
        'updateAppMessageShareData'
    ],
    /**
     * @url {String} [API post请求地址]
     * @type {String}
     */
    url: "/config/wechat",
    /**
     * [getSdkApi post请求获取SDK API]
     * 在vue-router下，ios验签需要用首次进入页面的url，安卓则需要用当前的url
     */
    getSdkApi: function() {
        return $.request({
            url: this.url,
            params: {
                url: Ua.isIOS() ? $.entryUrl : location.href.split('#')[0]
            },
            loading: { show: false }
        }, res => {
            return Promise.resolve(res);
        });
    }
};
export default WxSDK;
