var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.flag
    ? _c("div", { staticClass: "y_dialog_box" }, [
        _c(
          "div",
          { staticClass: "y_dialog" },
          [
            _c(
              "div",
              {
                staticClass: "y_clear_box",
                on: {
                  click: function ($event) {
                    return _vm.$emit("clear", $event)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "y_clear_icon",
                  attrs: {
                    src: "https://cdn.yitang.top/upload/ether-public/yitang/1668763203bde94985f3babbc45967d7e2a13827158ffc296c.svg",
                    alt: "",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm._t("header", function () {
              return [
                _vm.title
                  ? _c("div", { staticClass: "y_header" }, [
                      _vm._v(_vm._s(_vm.title) + "\n                "),
                      _c("img", {
                        staticClass: "y_icon1",
                        attrs: {
                          src: "https://cdn.yitang.top/upload/ether-public/yitang/16687621310f318ed35609965bbe7e5cb3b91ad3d0a6a4dca4.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "y_icon2",
                        attrs: {
                          src: "https://cdn.yitang.top/upload/ether-public/yitang/16687621310f318ed35609965bbe7e5cb3b91ad3d0a6a4dca4.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "y_icon3",
                        attrs: {
                          src: "https://cdn.yitang.top/upload/ether-public/yitang/166876213179fc5dc3f39ec726052e6301d544af3449aaad7f.png",
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm._t("default", function () {
              return [
                _vm.content
                  ? _c("div", {
                      staticClass: "y_body",
                      domProps: { innerHTML: _vm._s(_vm.content) },
                    })
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm._t("footer", function () {
              return [
                _vm.showCancel || _vm.showConfirm
                  ? _c("div", { staticClass: "y_dialog_footer" }, [
                      _vm.showCancel
                        ? _c(
                            "div",
                            {
                              staticClass: "y_cancel",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("cancel", $event)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.cancelText))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showConfirm
                        ? _c(
                            "div",
                            {
                              staticClass: "y_confirm",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("confirm", $event)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.confirmText) + "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }