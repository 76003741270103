import { render, staticRenderFns } from "./h1-block.vue?vue&type=template&id=3e42fd28&"
import script from "./h1-block.vue?vue&type=script&lang=js&"
export * from "./h1-block.vue?vue&type=script&lang=js&"
import style0 from "./h1-block.vue?vue&type=style&index=0&id=3e42fd28&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports