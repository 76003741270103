var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block docx-synced_reference-block synced-reference-ready" },
    [_vm._v("\n        这个内容块暂不支持，请联系教研修改\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }