<template>
    <div v-if="flag" class="y_dialog_box">
        <div class="y_dialog">
            <div @click="$emit('clear', $event)" class="y_clear_box">
                <img class="y_clear_icon"
                    src="https://cdn.yitang.top/upload/ether-public/yitang/1668763203bde94985f3babbc45967d7e2a13827158ffc296c.svg"
                    alt="">
            </div>
            <slot name="header">
                <div v-if="title" class="y_header">{{ title }}
                    <img class="y_icon1"
                        src="https://cdn.yitang.top/upload/ether-public/yitang/16687621310f318ed35609965bbe7e5cb3b91ad3d0a6a4dca4.png"
                        alt="">
                    <img class="y_icon2"
                        src="https://cdn.yitang.top/upload/ether-public/yitang/16687621310f318ed35609965bbe7e5cb3b91ad3d0a6a4dca4.png"
                        alt="">
                    <img class="y_icon3"
                        src="https://cdn.yitang.top/upload/ether-public/yitang/166876213179fc5dc3f39ec726052e6301d544af3449aaad7f.png"
                        alt="">
                </div>
            </slot>
            <slot>
                <div v-if="content" class="y_body" v-html="content"></div>
            </slot>
            <slot name="footer">
                <div v-if="showCancel || showConfirm" class="y_dialog_footer">
                    <div class="y_cancel" v-if="showCancel" @click="$emit('cancel', $event)">{{ cancelText }}</div>
                    <div class="y_confirm" v-if="showConfirm" @click="$emit('confirm', $event)">{{ confirmText }}
                    </div>
                </div>
            </slot>
        </div>
    </div>
</template>
    
<script>
/* @props
 *
 * show (显示dialog)
 *
 * title (标题)
 *
 * content (文本内容)
 *
 * cancelText (取消按钮文案)
 *
 * confirmText (确定按钮文案)
 *
 * showCancel (是否显示取消按钮)
 *
 * showConfirm (是否展示确定按钮)
 *
 */

/* @events
 *
 * cancel (点击取消按钮)
 *
 * confirm (点击确定按钮)
 */
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: '取消'
        },
        confirmText: {
            type: String,
            default: '确定'
        },
        showCancel: {
            type: Boolean,
            default: true
        },
        showConfirm: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            flag: false
        }
    },
    watch: {
        show(value) {
            this.flag = value
        }
    },
    created() {
        this.flag = this.show
    }, 
    methods: {
    }
};
</script>
<style lang="less">
@import (reference) "~mobile-base.less";

.y_dialog_box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .y_dialog {
        width: 626/@base;
        background: #FFFFFF;
        border-radius: 20/@base;
        position: relative;

        .y_clear_box {
            position: absolute;
            width: 48/@base;
            height: 48/@base;
            background: #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -24/@base;
            right: -24/@base;
            z-index: 10;
            cursor: pointer;

            .y_clear_icon {
                width: 30/@base;
                height: 30/@base;
            }
        }

    }

    .y_header {
        position: relative;
        height: 111/@base;
        background: #B9996C;
        border-radius: 20/@base 20/@base 0px 0px;
        display: flex;
        align-items: center;
        font-size: 40/@base;
        color: #FFFFFF;
        padding-left: 32/@base;
        box-sizing: border-box;
        overflow: hidden;

        .y_icon1 {
            position: absolute;
            width: 74/@base;
            height: 101/@base;
            top: 0;
            right: -15/@base;
        }

        .y_icon2 {
            position: absolute;
            width: 74/@base;
            height: 101/@base;
            bottom: -30/@base;
            right: 259/@base;
        }

        .y_icon3 {
            position: absolute;
            width: 217/@base;
            top: -30/@base;
            right: 32/@base;
        }
    }

    .y_body {
        font-size: 30/@base;
        color: #222222;
        line-height: 60/@base;
        padding: 32/@base;
        box-sizing: border-box;
    }

    .y_dialog_footer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 42/@base;

        .y_confirm {
            cursor: pointer;
            background: #B9996C;
            border: 2/@base solid #B9996C;
            border-radius: 52/@base;
            font-size: 26/@base;
            color: #FFFFFF;
            padding: 20/@base 60/@base;
        }

        .y_cancel {
            cursor: pointer;
            border: 2/@base solid #B9996C;
            border-radius: 52/@base;
            font-size: 26/@base;
            color: #B9996C;
            padding: 20/@base 60/@base;
        }
    }
}
</style>