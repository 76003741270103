<template>
  <div
    v-if="children"
    class="block docx-heading2-block"
    :class="[showSwitch ? 'mb-50' : '', `block-${children.blockId}`,  children.blockStyles && children.blockStyles.textBackground ? 'background':'']"
  >
    <div class="heading-content" :class="pageStyle">
      <el-popover
        v-model="showCatalog"
        v-if="showSwitch"
        placement="right-start"
        width="200"
        trigger="click"
      >
        <div class="van-cell-group van-hairline--top-bottom">
          <div
            class="van-cell"
            v-for="(item, index) in children.childrens"
            :key="item.blockId"
            @click="JumpPage(index)"
          >
            <div class="van-cell__title">
              <span>{{ getLabelText(item.blockAttr.heading3.elements) }}</span>
            </div>
            <div class="van-cell__value" v-if="switchType == 2 || switchType == 3">
              <span>
                <van-icon color="#f56c6c" name="like" v-show="isLike(item.blockId)" />
                <van-icon name="share" v-show="isPass(item.blockId)"
              /></span>
            </div>
          </div>
        </div>
        <van-icon slot="reference" class="catalog" name="filter-o" color="#409eff" />
      </el-popover>
      <v-spantext :elements="elements" :children="children"></v-spantext>
    </div>
    <div v-if="mode == 0">
      <slot></slot>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <toggleContent v-if="mode == 0" :fold="children.fold" :blockId="children.blockId" :foldChild="children.foldChild"></toggleContent>
    <switchBlock v-if="showSwitch" :fatherIndex="index" :source="children" ref="switchBlock" @push="tempPush"></switchBlock>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";
import switchBlock from "./switch-block.vue";
import { Icon } from "vant";
import toggleContent from "./toggle-content.vue";

export default {
  name: "h2-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
    index:{
      type: Number,
      default: 0,
    }
  },
  components: {
    switchBlock,
    toggleContent,
    "van-icon": Icon,
  },
  inject: ["blockActions", "targetBlockId"],
  data() {
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
      mode: this.$route.query.mode || '0',
      // targetBlockId: this.$route.query.blockId,
      switchType: this.$route.query.switchType,
      showCatalog: false,
      tempAction:[]
    };
  },
  computed: {
    showSwitch(){
      return (this.mode == 1 || this.mode == 2) && this.targetBlockId() == this.children.blockId
    },
    elements() {
      return this.children.blockAttr.heading2.elements || [];
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.heading2.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
     hblockActions() {
      return this.blockActions().concat(...this.tempAction);
    },
  },
  methods: {
    tempPush(params){
        this.tempAction.push(params);
    },
    isPass(curBlockId) {
      return this.hblockActions.some((item) => {
        return item.blockId == curBlockId && item.type == "pass";
      });
    },
    isLike(curBlockId) {
      return this.hblockActions.some((item) => {
        return item.blockId == curBlockId && item.type == "zan";
      });
    },
    getLabelText(elements) {
      let str = ``;
      elements.forEach((item) => {
        str += item.text_run ? item.text_run.content : '';
      });
      return str;
    },
    JumpPage(index) {
      this.$refs.switchBlock.setPageIndex(index);
      this.showCatalog = false;
    },
  },
};
</script>
<style >
.page-block-children .block.docx-heading2-block {
  margin-top: 22px;
  margin-bottom: 8px;
  font-size: 22px;
  position: relative;
}
.block.docx-heading2-block .heading-content{
  display: flex;
}
.mb-50 {
  margin-bottom: 58px !important;
}
.catalog {
  cursor: pointer;
}
.van-cell-group {
  background-color: #fff;
  max-height: 300px;
  overflow: auto;
}
.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
.van-cell:hover {
  background: #ecf5ff;
}
.van-cell__title {
  flex: 1;
  cursor: pointer;
}
.van-cell__value {
  position: relative;
  overflow: hidden;
  color: #969799;
  text-align: right;
  vertical-align: middle;
  word-wrap: break-word;
}
.block.docx-heading2-block .el-icon-caret-bottom{
  display: none;
}
.listitem:hover .block.docx-heading2-block .el-icon-caret-bottom{
  display: inline-block;
}
</style>