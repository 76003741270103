<template>
  <td
    :rowspan="crspan[children.blockId].row_span"
    :colspan="crspan[children.blockId].col_span"
    class="block table-cell-block table-cell-block-host cell-26 table-cell-content-wrapper docx-table_cell-block"
    :style="{display:hideRowCells.indexOf(children.blockId) > -1
    ? 'none' : '', ...blockStyles}"
  >
    <div class="render-unit-wrapper">
       <template v-for="(child, index) in children.childrens">
          <page-main
            :childLen="children.childrens.length"
            :level="-1"
            :olIndex="0"
            :source="child"
            :key="child.blockId"
            :index="index"
            :sources="children.childrens"
            :fatherType="children.type"
            :fatherBlockId="children.blockId"
            :prevChild="index - 1 >= 0 ? children.childrens[index - 1] : null"
          ></page-main>
        </template>
    </div>
  </td>
</template>
<script>

import pageMain from "./page-main.vue";
export default {
  name: "table-cell-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
    hideRowCells: {
      type: Array,
      default() {
        return [];
      },
    },
    crspan: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components:{
    pageMain
  },
  computed:{
    blockStyles(){
      let style = this.children.blockStyles
      if(style){
        return style;
      }
      return '';
    }
  },
  data() {
    return {};
  },
  mounted() {
   
  },
  methods: {},
};
</script>
<style >
.table-cell-block {
  height: 1px;
  margin: 0;
  padding: 0;
  border-right: var(--table-block-border-width) solid var(--N300);
  border-bottom: var(--table-block-border-width) solid var(--N300);
  vertical-align: top;
  overflow: hidden;
  display: table-cell;
}
.table-cell-content-wrapper {
  min-height: 39px;
  min-width: 50px;
  font-size: 12px;
  padding: 8px;
}
.page-block-children .block .table-cell-block-host {
  margin: 0 !important;
}
tr:first-child > .table-cell-block {
  border-top: var(--table-block-border-width) solid var(--N300);
}
tr .table-cell-block:first-child {
  border-left: var(--table-block-border-width) solid var(--N300);
}
.table-cell-content-wrapper > .render-unit-wrapper > .block:last-child {
  margin-bottom: 0;
}
.table-cell-content-wrapper > .render-unit-wrapper > .block:first-child {
  margin-top: 0;
}
</style>