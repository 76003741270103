<template>
  <div class="page-block-header" :class="[`item-${children.blockId}`, children.blockStyles && children.blockStyles.textBackground ? 'background':'']">
    <div class="page-block-content" :class="pageStyle">
      <v-spantext :elements="elements" :children="children"></v-spantext>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";

export default {
  name: "page-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.setDocTtile();
  },
  computed: {
    elements() {
      return this.children.blockAttr.page.elements || [];
    },
    pageStyle() {
      let dataStyle = this.children.blockAttr.page.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
  },
  data() {
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
    };
  },
  methods: {
    setDocTtile() {
      let content = "";
      for (let i = 0; i < this.elements.length; i++) {
        let item = this.elements[i];
        content += item.text_run.content;
      }
      content = content || "未命名文档"
      this.$store.commit("setFsDocTitle", content);
      document.title = content;
    },
  },
};
</script>
<style>
.page-block-header {
  position: relative;
  z-index: 0;
  padding: 20px 0 0 20px;
  margin: 0 0 22px;
  word-break: break-word;
}
.page-block-content {
  position: relative;
  font-size: 34px;
  min-height: 55.25px;
  line-height: 1.625;
  color: var(--text-title);
  font-weight: 500;
}
</style>