var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "block docx-file-block is-in-video-v2",
      class: `item-${_vm.children.blockId}`,
    },
    [
      _vm.showFileDIv
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { attrs: { contenteditable: "false" } }, [
              _c(
                "div",
                {
                  staticClass:
                    "docx-file-block-container docx-view-type-Preview",
                },
                [
                  _c("div", { staticClass: "preview-card-header" }, [
                    _c("div", {
                      staticClass:
                        "preview-card-header__mask preview-card-header__mask-video",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "preview-card-header__container" },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "18",
                              height: "18",
                              viewBox: "0 0 32 32",
                            },
                          },
                          [
                            _c("defs", [
                              _c("path", {
                                attrs: {
                                  d: "M1.5 0h14.086a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V26.5a1.5 1.5 0 01-1.5 1.5h-19A1.5 1.5 0 010 26.5v-25A1.5 1.5 0 011.5 0z",
                                  id: "icon_file_video_nor_svg__a",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d: "M16.293.293l5.414 5.414A1 1 0 0121.91 6H17.5A1.5 1.5 0 0116 4.5V.09a1 1 0 01.293.203z",
                                  id: "icon_file_video_nor_svg__b",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "g",
                              {
                                attrs: { fill: "none", "fill-rule": "evenodd" },
                              },
                              [
                                _c(
                                  "g",
                                  { attrs: { transform: "translate(5 2)" } },
                                  [
                                    _c("use", {
                                      attrs: {
                                        fill: "#3370FF",
                                        "xlink:href":
                                          "#icon_file_video_nor_svg__a",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("use", {
                                      attrs: {
                                        fill: "#245BDB",
                                        "xlink:href":
                                          "#icon_file_video_nor_svg__b",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: { d: "M7 11h16.649v16.649H7z" },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M10.757 15h7.486c.383 0 .7.285.75.654l.007.103v7.486c0 .383-.285.7-.654.75l-.103.007h-7.486a.757.757 0 01-.75-.654L10 23.243v-7.486c0-.383.285-.7.654-.75l.103-.007h7.486zm11.819 1.792a.568.568 0 01.075.282v4.854a.568.568 0 01-.85.493l-1.799-1.028v-3.784l1.8-1.028a.568.568 0 01.774.211zM13.1 16.5h-1.2a.4.4 0 00-.4.4v1.2c0 .22.18.4.4.4h1.2a.4.4 0 00.4-.4v-1.2a.4.4 0 00-.4-.4z",
                                    fill: "#FFF",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "preview-card-header__container-title",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.children.blockAttr.file.name.slice(0, -4)
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("video", {
                    attrs: {
                      controls: "",
                      playsinline: "true",
                      "x5-playsinline": "true",
                      "webkit-playsinline": "true",
                      mediatype: "video",
                      "data-index": "-1",
                      id: `video-${_vm.children.blockId}`,
                      src: _vm.videoUrl,
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._m(1),
          ]
        : _c("div", { staticClass: "error-video-tip" }, [
            _vm._v("\n    视频仅支持MP4格式\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "docx-block-zero-space" }, [
      _c("span", { attrs: { "data-zero-space": "true" } }, [_vm._v("​")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }