import vway from 'ether-vway';
import * as bekit from 'bekit';
import hosts from '@/config/hosts-local.json';

const { Ua, Url } = bekit.helper;

export function hasToken() {
  let token = vway.ls.getGlobal('TOKEN');
  return !!token;
}
// 在里面写白名单
export function canPreLogin(to) {
  return false;
  if (!Ua.isWx()) return false;
  return to.meta.baseAuth || false
}

// 假登录，依靠sso的session储存用户身份。这个用户等同于未填写学籍的用户。
export function canFakeLogin(to) {
  return to.meta.fakeLogin || false
}


export function preLogin(to) {
  let href = to ? location.origin + to.fullPath : location.href;
  let currentUrl = new Url(href);
  currentUrl.params && delete currentUrl.params.token;
  currentUrl.params && delete currentUrl.params.cancel;
  let afterLoginParams = {
    after_login: encodeURIComponent(currentUrl.fullPath)
  }
  let redirectUriParams = {
    isBase: currentUrl.params ? currentUrl.params.isBase : 0,
    appname: 'yitang',
    wxapp: 'yt_wxapp', // 区分是哪个微信公众号或者网站应用
    gotoURL: encodeURIComponent(location.origin + '/login?' + Url.stringifyParams(afterLoginParams)),
  };

  let loginPath = 'https://open.weixin.qq.com/connect/oauth2/authorize';
  let loginParams = {
    appid: hosts.appid,
    response_type: 'code',
    scope: 'snsapi_base',
    state: 'STATE',
    redirect_uri: encodeURIComponent(hosts.sso_url + 'account/wxbaselogin?' + Url.stringifyParams(redirectUriParams)),
  }
  let result = loginPath + '?' + Url.stringifyParams(loginParams);
  location.replace(result);
}
