<template>
    <div class="block docx-synced_reference-block synced-reference-ready">
        <!-- <slot></slot> -->
            这个内容块暂不支持，请联系教研修改
    </div>
</template>
<script>
export default {
    name:'synced-reference-block'
}
</script>