const pageStyles = {
    align: {
        '1': 'left',
        '2': 'center',
        '3': 'right',
    }
}
const  flexAlign = {
    '1': 'list-align-left',
    '2': 'list-align-center ',
    '3': 'list-align-right',
}
const FontColor = {
    '1': 'textHighlight textHighlight-pink-text',
    '2': 'textHighlight textHighlight-orange-text',
    '3': 'textHighlight textHighlight-yellow-text',
    '4': 'textHighlight textHighlight-green-text',
    '5': 'textHighlight textHighlight-blue-text',
    '6': 'textHighlight textHighlight-purple-text',
    '7': 'textHighlight textHighlight-gray-text',
}
const FontBackgroundColor = {
    '1': 'text-highlight-background text-highlight-background-pink-light-bg',
    '2': 'text-highlight-background text-highlight-background-orange-light-bg',
    '3': 'text-highlight-background text-highlight-background-yellow-light-bg',
    '4': 'text-highlight-background text-highlight-background-green-light-bg',
    '5': 'text-highlight-background text-highlight-background-blue-light-bg',
    '6': 'text-highlight-background text-highlight-background-purple-light-bg',
    '7': 'text-highlight-background text-highlight-background-gray-light-bg',
    '8': 'text-highlight-background text-highlight-background-pink-dark-bg',
    '9': 'text-highlight-background text-highlight-background-orange-dark-bg',
    '10': 'text-highlight-background text-highlight-background-yellow-dark-bg',
    '11': 'text-highlight-background text-highlight-background-green-dark-bg',
    '12': 'text-highlight-background text-highlight-background-blue-dark-bg',
    '13': 'text-highlight-background text-highlight-background-purple-dark-bg',
    '14': 'text-highlight-background text-highlight-background-gray-dark-bg',
}
const elementStyles = {
    "bold": 'bold',
    "inline_code": 'inline_code',
    "italic": 'italic',
    "strikethrough": 'strikethrough',
    "underline": 'underline',
    "text_color": FontColor,
    'background_color': FontBackgroundColor,
    "link": 'link-text-color'
}
const BorderColor = {
    '1': '--ccmtoken-doc-calloutborder-red',
    '2': '--ccmtoken-doc-calloutborder-orange',
    '3': '--ccmtoken-doc-calloutborder-yellow-solid',
    '4': '--ccmtoken-doc-calloutborder-green',
    '5': '--ccmtoken-doc-calloutborder-blue',
    '6': '--ccmtoken-doc-calloutborder-purple',
    '7': '--ccmtoken-doc-blockbackground-gray',
}
const CalloutBackgroundColor = {
    '1': '--ccmtoken-doc-highlightcolor-bg-red-soft',
    '2': '--ccmtoken-doc-highlightcolor-bg-orange-soft',
    '3': '--ccmtoken-doc-highlightcolor-bg-sunflower-soft',
    '4': '--ccmtoken-doc-highlightcolor-bg-green-soft',
    '5': '--ccmtoken-doc-highlightcolor-bg-blue-soft',
    '6': '--ccmtoken-doc-highlightcolor-bg-purple-soft',
    '7': '--ccmtoken-doc-blockbackground-netural',
    '8': '--ccmtoken-doc-highlightcolor-bg-red-solid',
    '9': '--ccmtoken-doc-highlightcolor-bg-orange-solid',
    '10': '--ccmtoken-doc-highlightcolor-bg-sunflower-solid',
    '11': '--ccmtoken-doc-highlightcolor-bg-green-solid',
    '12': '--ccmtoken-doc-highlightcolor-bg-blue-solid',
    '13': '--ccmtoken-doc-highlightcolor-bg-purple-solid',
    '14': '--ccmtoken-doc-blockbackground-netural-solid',
}

function getEleStyle(styles) {
    let className = "";
    for (let key in styles) {
        let value = styles[key];
        let eleValue = elementStyles[key]
        if(eleValue === undefined) {
        }else if (typeof eleValue === "string") {
            if(value) className += eleValue + " ";
        } else {
            if(typeof value === "string" || typeof value === "number"){
                className += eleValue[value] + " ";

            }else{
                className += eleValue[key] + " ";
            }
        }
    }
    return className;
}

function getAlignFlex(align){
    return flexAlign[align]
}
export {
    pageStyles,
    elementStyles,
    BorderColor,
    CalloutBackgroundColor,
    getEleStyle,
    getAlignFlex
}