<template>
  <div v-if="children" class="block docx-quote_container-block">
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
    <div class="quote-container-block">
        <div class="quote-container-block-children">
            <slot></slot>
        </div>
    </div>
    <div class="docx-block-zero-space">
      <span data-zero-space="true">&ZeroWidthSpace;</span>
    </div>
  </div>
</template>
<script>

export default {
  name: "quote-container-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style>
.block.docx-quote_container-block {
  padding-left: 2px;
  padding-right: 2px;
  margin: 8px -2px 8px 0;
}
.block.docx-quote_container-block .quote-container-block {
  padding-left: 12px;
  margin-right: -2px;
  padding-right: 2px;
  position: relative;
}
.block.docx-quote_container-block .quote-container-block:before {
    width: 2px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2px;
    background: var(--udtoken-quote-bar-bg);
    border-radius: 1px;
    -webkit-transform-origin: top;
    transform-origin: top;
}
.block.docx-quote_container-block .textHighlight, .block.docx-quote_container-block .textHighlightBackground {
    opacity: .7;
}
</style>