<template>
  <div v-if="children" class="block docx-heading5-block" :class="[`block-${children.blockId}`, children.blockStyles && children.blockStyles.textBackground ? 'background':'']">
    <div class="heading-content" :class="pageStyle">
      <v-spantext :elements="elements" :children="children"></v-spantext>
    </div>
    <div >
      <slot></slot>
    </div>
    <v-highlight :blockId="children.blockId"></v-highlight>
    <v-blockbackground v-if="children.blockStyles && children.blockStyles.textBackground" :backgroundColor="children.blockStyles.textBackground"></v-blockbackground>
  </div>
</template>
<script>
import { pageStyles, getEleStyle } from "../utils/style";
import { isUrlRegExp, openUrl } from "../utils/utils";

export default {
  name: "h5-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      getEleStyle,
      isUrlRegExp,
      openUrl,
    };
  },
  computed: {
    heading(){
      let obj = this.children.blockAttr.heading5 || 
      this.children.blockAttr.heading6 || 
      this.children.blockAttr.heading7 || 
      this.children.blockAttr.heading8 || 
      this.children.blockAttr.heading9 || 
      undefined;
      return obj
      
    },
    elements() {
      if(!this.heading){
        return []
      }
      return this.heading.elements || [];
    },
    pageStyle() {
      if(!this.heading){
        return ''
      }
      let dataStyle = this.heading.style;
      let className = "";
      for (let key in dataStyle) {
        if (pageStyles[key]) {
          let value = dataStyle[key];
          className += pageStyles[key][value] + "";
        }
      }
      return className;
    },
  },
  methods: {},
};
</script>
<style >
.page-block-children .block.docx-heading5-block {
  margin-top: 18px;
  margin-bottom: 8px;
  font-size: 16px;
}
</style>